import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useQuery } from 'react-query'
import { getMenus } from '../api/menus'
import { CooMenu } from '../types/menu'
import { useFulfillment } from '../components/FulfillmentProvider/FulfillmentProvider'
import { ItemQuantityRuleView } from '../types/config'

export const useMenus = (): {
  menus?: CooMenu[]
  error: boolean
  loading: boolean
  refetch: () => void
  menuItemQuantityRules: ItemQuantityRuleView[]
} => {
  const { restaurantGuid, ooConfig } = useRestaurant()
  const { diningOptionBehavior, fulfillmentDateTime, fastLinkName } =
    useFulfillment()

  const {
    data,
    isError: error,
    isLoading,
    refetch
  } = useQuery(
    ['menus', restaurantGuid, diningOptionBehavior, fulfillmentDateTime],
    () => getMenus(diningOptionBehavior, fulfillmentDateTime, fastLinkName)
  )

  return {
    menus: data?.menus,
    error,
    loading: isLoading,
    refetch,
    menuItemQuantityRules: ooConfig?.itemQuantityRules ?? []
  }
}
