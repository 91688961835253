import { SelectedCreditCard, useOptionalPayment } from './PaymentContext'
import {
  useDeleteCreditCardMutation,
  useMakeCreditCardPrimaryMutation
} from './account-mutations'
import { useCustomerAuth } from '../auth/CustomerAuthContext'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { SavedCreditCard } from './SavedCreditCard'

import styles from './account.module.css'
import { ListItem, MenuDropdown } from '@toasttab/buffet-pui-dropdowns'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { MoreVertIcon } from '@toasttab/buffet-pui-icons'

type CreditCardRowProps = {
  guid?: string
  encryptionKeyId?: string
  cardType?: string
  maskedPan: string
  expirationMonth?: string | null
  expirationYear?: string | null
  isPrimary: boolean
  onError: (err: string) => void
}

export const CreditCardRow = ({
  guid,
  encryptionKeyId,
  cardType,
  maskedPan,
  expirationMonth,
  expirationYear,
  isPrimary,
  onError
}: CreditCardRowProps) => {
  const {
    setNewCreditCard,
    selectedCreditCard,
    setSelectedCreditCard,
    paymentOption
  } = useOptionalPayment() || {}
  const [deleteCreditCardMutation] = useDeleteCreditCardMutation()
  const [makeCreditCardPrimaryMutation] = useMakeCreditCardPrimaryMutation()
  const { refetchCustomer } = useCustomerAuth()
  const [cardLoading, setCardLoading] = useState(false)
  const apolloClient = useApolloClient()

  const removeCard = useCallback(async () => {
    setCardLoading(true)
    if (encryptionKeyId) {
      setNewCreditCard && setNewCreditCard(null)
    } else if (guid) {
      const { data } = await deleteCreditCardMutation({
        variables: { input: { cardGuid: guid } },
        client: apolloClient
      })
      if (data?.deleteCreditCard.__typename === 'DeleteCreditCardResponse') {
        setSelectedCreditCard &&
          setSelectedCreditCard((selectedCard: SelectedCreditCard | null) =>
            selectedCard
              ? {
                  ...selectedCard,
                  savedCardGuid:
                    selectedCard?.savedCardGuid === guid
                      ? null
                      : selectedCard?.savedCardGuid
                }
              : null
          )
        refetchCustomer()
        onError('')
      } else {
        onError(
          'There was an error while updating your card. Please try again.'
        )
      }
    }
    setCardLoading(false)
  }, [
    encryptionKeyId,
    guid,
    setNewCreditCard,
    setSelectedCreditCard,
    deleteCreditCardMutation,
    refetchCustomer,
    onError,
    apolloClient
  ])

  const primaryCard = useCallback(async () => {
    setCardLoading(true)
    if (guid) {
      const { data } = await makeCreditCardPrimaryMutation({
        variables: { input: { cardGuid: guid } },
        client: apolloClient
      })
      if (
        data?.makeCreditCardPrimary.__typename ===
        'MakeCreditCardPrimaryResponse'
      ) {
        await refetchCustomer()
        onError('')
      } else {
        onError(
          'There was an error while updating your card. Please try again.'
        )
      }
    }
    setCardLoading(false)
  }, [
    guid,
    makeCreditCardPrimaryMutation,
    refetchCustomer,
    onError,
    apolloClient
  ])

  const isChecked = useMemo(() => {
    if (selectedCreditCard?.newCardSelected) {
      return Boolean(encryptionKeyId)
    } else if (selectedCreditCard?.savedCardGuid) {
      return selectedCreditCard.savedCardGuid === guid
    } else {
      return isPrimary
    }
  }, [selectedCreditCard, encryptionKeyId, guid, isPrimary])

  useEffect(() => {
    if (isChecked) {
      setSelectedCreditCard &&
        setSelectedCreditCard({
          newCardSelected: Boolean(encryptionKeyId),
          savedCardGuid: guid || null
        })
    }
  }, [isChecked, encryptionKeyId, guid, setSelectedCreditCard, paymentOption])

  if (!guid && !encryptionKeyId) {
    // when a new card is removed, show a skeleton until the animation completes
    return (
      <div className={styles.savedCreditCard}>
        <Loading variant={LoadingVariant.SECONDARY} />
      </div>
    )
  }

  return (
    <div className={styles.savedCreditCard} role='listitem' tabIndex={0}>
      {cardLoading ? (
        <Loading variant={LoadingVariant.SECONDARY} />
      ) : (
        <div className={styles.savedCardRadio}>
          <SavedCreditCard
            maskedPan={maskedPan}
            expirationMonth={expirationMonth}
            expirationYear={expirationYear}
            cardType={cardType}
          />
        </div>
      )}
      <div>
        <MenuDropdown
          variant={'gray'}
          renderToggle={(props) => (
            <IconButton
              {...props}
              aria-label='Edit card'
              icon={<MoreVertIcon accessibility='decorative' />}
              textClassName='text-secondary'
            />
          )}
        >
          {guid && !isPrimary && (
            <ListItem label={'Make primary'} onClick={primaryCard} />
          )}
          <ListItem label={'Delete card'} onClick={removeCard} />
        </MenuDropdown>
      </div>
    </div>
  )
}
