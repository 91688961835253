import React, { useCallback, useEffect, useState } from 'react'
import { useSpiSdk } from './useSpi'
import {
  createPaymentIntent,
  PaymentIntent,
  updatePaymentIntent as updatePaymentIntentApi
} from '../../api/payments'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { ErrorComponent, getErrorData } from '@local/do-secundo-error'
import ContentLoader from 'react-content-loader'
import {
  useDebounce,
  useEffectOnChange,
  usePreviousHandleStateChange
} from '../../hooks/util'
import { InputChangedEvent } from './types'
import { useCustomerAuth } from '../../auth/CustomerAuthContext'
import { useSentry } from 'banquet-runtime-modules'
import { useTracker } from '../../analytics/tracker'

const SPI_IFRAME_ANCHOR_ID = 'spi-frame-anchor'

type Props = {
  cartGuid: string
  amount: number
  tipAmount: number
  email: string
  field: { name: string }
  form: { setFieldValue: (name: string, value: boolean) => void }
  errorAuthorizingPayment?: Error | string
}

type UpdateOrCreateParams = {
  amount: number
  tipAmount: number
  email: string
  paymentIntent: PaymentIntent | undefined
  failureOccurred?: boolean
}

export const CreditCardFormSpi = ({
  cartGuid,
  amount,
  tipAmount,
  email,
  field,
  form,
  errorAuthorizingPayment
}: Props) => {
  const spiSdk = useSpiSdk()
  const { captureException } = useSentry()
  const { restaurantGuid, restaurantInfo } = useRestaurant()
  const tracker = useTracker()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [spiFormMounted, setSpiFormMounted] = useState(false)
  const [spiFormNeedsUpdate, setSpiFormNeedsUpdate] = useState(false)
  const [customerAuthChanged, setCustomerAuthChanged] = useState(false)

  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>()
  const [paymentFormValid, setPaymentFormValid] = useState(false)

  const setCheckoutPaymentFieldValid = (isValid: boolean) =>
    form.setFieldValue(field.name, isValid)

  useEffect(() => {
    setCheckoutPaymentFieldValid(paymentFormValid)
    //eslint-disable-next-line
  }, [paymentFormValid])

  useEffect(() => {
    // Temporarily disables the submit button while its waiting for the
    // SPI form to be updated due to a change (tip amount, email, etc.)
    if (spiFormNeedsUpdate) {
      setCheckoutPaymentFieldValid(false)
    } else {
      setCheckoutPaymentFieldValid(paymentFormValid)
    }
    //eslint-disable-next-line
  }, [spiFormNeedsUpdate])

  const createOrUpdatePaymentIntent = (params: UpdateOrCreateParams) => {
    if (params.amount > 0) {
      if (!params.paymentIntent) {
        createPaymentIntent(cartGuid, {
          amount: params.amount,
          currency: 'USD',
          email: params.email
        })
          .then((resp) => {
            setPaymentIntent(resp)
            // The payment intent API currently doesn't take in a tip amount
            // in the create request. If the tip is already set due to a
            // restaurant default, we need to kick off an update request.
            if (params.tipAmount > 0) {
              updatePaymentIntent({
                ...params,
                paymentIntent: resp
              })
            }
          })
          .catch(async (err) => {
            const errorMsg = (await getErrorData(err)).message
            captureException(
              new Error(`SPI - Failed to create payment intent: ${errorMsg}`)
            )
            setLoading(false)
            setError(true)
          })
      } else {
        updatePaymentIntent(params)
      }
    }
  }

  const updatePaymentIntent = (params: UpdateOrCreateParams) => {
    if (!params.paymentIntent) return
    updatePaymentIntentApi(cartGuid, params.paymentIntent.paymentIntentId, {
      amount: params.amount,
      tipAmount: params.tipAmount,
      email: params.email,
      oAuthToken: params.paymentIntent.oauthToken,
      newReferenceId: params.failureOccurred
    })
      .then((resp) => {
        setPaymentIntent(resp)
        tracker.trackUpdatePaymentIntentPaymentSuccess(restaurantGuid)
      })
      .catch(async (err) => {
        const errorMsg = (await getErrorData(err)).message
        captureException(
          new Error(
            `SPI - Failed to update payment intent for cart ${cartGuid}: ${errorMsg}`
          )
        )
        tracker.trackUpdatePaymentIntentPaymentFailure(restaurantGuid)
        setLoading(false)
        setError(true)
      })
  }

  const createOrUpdatePaymentIntentDebounced = useDebounce(
    createOrUpdatePaymentIntent,
    1000
  )

  useEffect(() => {
    // Create the payment intent on page mount
    createOrUpdatePaymentIntent({
      amount,
      tipAmount,
      email,
      paymentIntent
    })
    //eslint-disable-next-line
  }, [])

  useEffectOnChange(() => {
    // Update the payment intent when any of these fields change,
    // debounced to account for user typing (email and tip for ex.)
    // Checking for customerAuthChanged to prevent double-firing
    // when the email changed due to authed customer change
    if (!customerAuthChanged) {
      createOrUpdatePaymentIntentDebounced({
        amount,
        tipAmount,
        email,
        paymentIntent
      })
      setSpiFormNeedsUpdate(true)
    }
    //eslint-disable-next-line
  }, [amount, tipAmount, email])

  const { customer } = useCustomerAuth()
  const prevCustomer = usePreviousHandleStateChange(customer)

  useEffectOnChange(() => {
    // When the email changes, if it was from the logged-in customer changing,
    // need to create a new payment intent and re-initialize the payment form.
    if ((!customer && prevCustomer) || (customer && !prevCustomer)) {
      setCustomerAuthChanged(true)
      setSpiFormMounted(false)
      createOrUpdatePaymentIntent({
        amount,
        tipAmount,
        email: customer ? customer.email : email,
        paymentIntent: undefined
      })
    }
    //eslint-disable-next-line
  }, [customer])

  useEffect(() => {
    // When an error occurs, we need update the payment intent
    // to generate a new externalReferenceId
    if (errorAuthorizingPayment) {
      createOrUpdatePaymentIntent({
        amount,
        tipAmount,
        email,
        paymentIntent,
        failureOccurred: true
      })
      setSpiFormNeedsUpdate(true)
    }
    //eslint-disable-next-line
  }, [errorAuthorizingPayment])

  const spiStatusCallback = useCallback(
    (e: InputChangedEvent) => {
      setPaymentFormValid(
        Boolean(e.content.selectedPaymentMethod && e.content.isValid)
      )
    },
    [setPaymentFormValid]
  )

  useEffect(() => {
    let frame: HTMLIFrameElement | undefined
    if (spiSdk && paymentIntent) {
      if (!spiFormMounted) {
        frame = spiSdk.initialize(
          (ev: any) => {
            setLoading(false)
            setError(false)
            setSpiFormMounted(true)
            setCustomerAuthChanged(false)
            spiStatusCallback(ev)
            spiSdk.monitor(spiStatusCallback)
            tracker.trackSpiInitializeSuccess(restaurantGuid)
          },
          (err) => {
            captureException(
              new Error(
                `SPI - Failed to initialize form: ${
                  err?.cause?.message ?? JSON.stringify(err)
                }`
              )
            )
            tracker.trackSpiInitializeFailure(restaurantGuid)
            setLoading(false)
            setError(true)
            setCustomerAuthChanged(false)
          },
          {
            domElementId: SPI_IFRAME_ANCHOR_ID,
            merchantId: restaurantGuid,
            oauthToken: paymentIntent.oauthToken,
            sessionSecret: paymentIntent.sessionSecret,
            acceptAmex: Boolean(restaurantInfo?.amexAccepted),
            zipRequired: true
          }
        )
      } else {
        spiSdk.fetchUpdates()
      }
      setSpiFormNeedsUpdate(false)
    }

    return () => {
      try {
        frame && document.removeChild(frame)
      } catch (e) {}
    }
    //eslint-disable-next-line
  }, [spiSdk, paymentIntent])

  return (
    <div id={SPI_IFRAME_ANCHOR_ID}>
      {loading && (
        <ContentLoader className='w-full'>
          <rect x='8' y='20%' rx='4' ry='4' width='100%' height='12' />
          <rect x='8' y='40%' rx='4' ry='4' width='100%' height='12' />
          <rect x='8' y='60%' rx='4' ry='4' width='100%' height='12' />
        </ContentLoader>
      )}
      {error && (
        <ErrorComponent
          error='Failed to load payment form. Please try refreshing the page.'
          isActionable={false}
        />
      )}
    </div>
  )
}
