import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { useGetCart } from '../CartQuery/CartQuery'
import { CloseButton } from '@local/do-secundo-closebutton'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { MessageError } from '@toasttab/do-secundo-message-error'
import { ToastContainer } from 'react-toastify'
import { CheckoutForm } from '../CheckoutForm/CheckoutForm'
import { CreditCardProvider } from '../CreditCardProvider/CreditCardProvider'
import { RestaurantInfo } from '../RestaurantInfo/RestaurantInfo'
import { ScrollToTop } from '../ScrollToTop/ScrollToTop'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { CartModificationError } from '../Cart/CartModificationError/CartModificationError'
import { ValidateCart } from '../ValidateCart/ValidateCart'
import style from './CheckoutPage.module.css'
import { InstagramWarning } from '../RestaurantPage/InstagramWarning'
import { CheckoutMode, useCustomerAuth } from '../../auth/CustomerAuthContext'
import { AccountModal } from '../../account/AccountModal'
import { PwlessAuthModal } from '../../auth/PwlessAuthModal'
import { AuthenticationSource } from '../../auth/auth'

const { toastContainer, toastWrapper } = style

export const CheckoutPage = () => {
  const { orderingAvailable } = useAvailability()
  const { getRestaurantPath } = useRestaurant()

  const [editingAccount, setEditingAccount] = useState(false)
  const [isAuthModalOpen, setIsAuthModalOpen] = React.useState(false)
  const [selectedCheckoutMode, setSelectedCheckoutMode] = useState(false)
  const { setCheckoutMode } = useCustomerAuth()

  const { error: getCartError, cart, loading, refetch } = useGetCart()

  if (getCartError) {
    return (
      <div className='px-10 mt-20'>
        <MessageError
          header='Unable to load checkout'
          message='Try refreshing the page, or contact the restaurant directly to order'
          buttonSecondary={{
            label: 'Refresh page',
            onClick: () => refetch()
          }}
          testId='checkout-load-error'
        />
      </div>
    )
  }

  if (!cart && !loading) {
    return <Navigate to={getRestaurantPath()} replace />
  }

  return (
    <CreditCardProvider>
      <div className={style.page} role='main' aria-label='checkout'>
        <ScrollToTop />
        <InstagramWarning />
        <RestaurantInfo
          header='Checkout'
          orderingAvailable={orderingAvailable}
        />
        <div className={style.cartButton}>
          <CloseButton
            data-testid='close-button-checkout-page'
            to={getRestaurantPath('cart')}
          />
        </div>
        <Link
          to={getRestaurantPath()}
          data-testid='back-to-menu-link'
          className={style.menuButton}
        >
          Back to menu
        </Link>
        <ValidateCart />
        <CartModificationError />
        <CheckoutForm
          setEditingAccount={setEditingAccount}
          setIsAuthModalOpen={setIsAuthModalOpen}
          setSelectedCheckoutMode={setSelectedCheckoutMode}
          selectedCheckoutMode={selectedCheckoutMode}
          isAuthModalOpen={isAuthModalOpen}
        />
      </div>
      <ToastContainer
        hideProgressBar
        closeButton={false}
        className={toastContainer}
        toastClassName={toastWrapper}
        position='top-center'
      />
      <AccountModal
        isOpen={editingAccount}
        onClose={() => setEditingAccount(false)}
      />
      <PwlessAuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        source={AuthenticationSource.ExpressCheckout}
        onSuccessfulLogin={() => {
          setCheckoutMode(CheckoutMode.Toast)
          setSelectedCheckoutMode(true)
        }}
      />
    </CreditCardProvider>
  )
}
