import React from 'react'
import { Field } from 'formik'
import uniqBy from 'lodash/uniqBy'
import { string } from 'yup'
import { Dollars } from '@local/do-secundo-dollars'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Radio } from '@local/do-secundo-form'
import { useGetCart } from '../../CartQuery/CartQuery'
import styles from './Tip.module.css'
import { CurrencyField } from '@toasttab/buffet-pui-forms'
import { InitialValuesArgs } from '../utils'
import { PaymentType } from '../../../types/orders'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'

const DOORDASH_MAX_TIP_AMOUNT = 120
const UBER_MAX_TIP_AMOUNT = 400

export const Component = () => {
  const { cart } = useGetCart()
  if (!cart) return null

  const { preComputedTips } = cart
  const uniquePreComputedTips = uniqBy(preComputedTips, 'percent')
  const fieldsetLabel = 'Tip'

  return (
    <Fieldset label={fieldsetLabel} collapsable id='tip_fields'>
      <div data-testid='label-tip' className={styles.tips}>
        <div className='flex mb-4 flex-column'>
          {uniquePreComputedTips &&
            uniquePreComputedTips.map((tip, index) => (
              <Field
                component={Radio}
                style='chunky'
                label={
                  <div className='flex flex-col items-center my-2'>
                    {tip.percent === 0 ? (
                      <span className={styles.percent}>No tip</span>
                    ) : (
                      <>
                        <span className={styles.percent}>
                          {/* Want to calculate tip.perecent * 100 - The rest
                              is necessary to fix rounding precision problem */}
                          {Math.round(tip.percent * 10000) / 100}%
                        </span>
                        <span className={styles.dollars}>
                          <Dollars amount={tip.value} />
                        </span>
                      </>
                    )}
                  </div>
                }
                data-testid={'percent-tip-' + tip.percent}
                name='paymentTip'
                id={`tip_${index}`}
                key={`tip_${index}`}
                percent={tip.percent}
                value={roundToNearestCent(tip.value)}
                containerClassName='py-2'
              />
            ))}
        </div>
        <CurrencyField
          data-testid='custom-tip'
          label='Custom amount'
          name='paymentTip'
          id='payment_tip'
          key='payment_tip'
          decimalScale={2}
          maxLength={8}
          allowNegative={false}
        />
      </div>
    </Fieldset>
  )
}

const roundToNearestCent = (tip: number) => Math.round(tip * 100) / 100

export const getInitialValues = ({ preComputedTips }: InitialValuesArgs) => {
  const tip = preComputedTips?.find((tip) => tip.isDefault)?.value
  return {
    paymentTip: tip ? roundToNearestCent(tip) : ''
  }
}

export const getValidationSchema = ({ tdsConfig, cartAmount }) => {
  const isDoorDash = tdsConfig.currentProvider === 'DOORDASH'
  const tipMax = isDoorDash ? DOORDASH_MAX_TIP_AMOUNT : UBER_MAX_TIP_AMOUNT
  return {
    paymentTip: string().when('paymentType', {
      is: (paymentType: PaymentType) =>
        paymentType === 'CREDIT' && tdsConfig.enabled,
      then: string()
        .default('')
        .test('max', `Tip cannot exceed $${tipMax}`, (value) => {
          const tip = parseFloat(value) || 0
          return tip <= tipMax
        })
        .test(
          'max',
          `Tip cannot exceed order total of ${formatCurrency(
            { amount: cartAmount, currency: Currency.USD },
            'en-US'
          )}`,
          (value) => {
            const tip = parseFloat(value) || 0
            return !isDoorDash || tip <= cartAmount
          }
        ),
      otherwise: string().default('')
    })
  }
}

export const getArgsForSubmit = ({ values }) => {
  return { tipAmount: parseFloat(values.paymentTip) || 0 }
}
