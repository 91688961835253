import { BaseSchema, boolean, object, string } from 'yup'
import { Customer, DeliveryInfo, PaymentType } from '../../types/orders'
import * as DynamicFormSection from '../DynamicForms/DynamicFormSection'
import * as CheckoutDeliveryInfo from './CheckoutDeliveryInfo/CheckoutDeliveryInfo'
import * as CustomerInfo from './CustomerInfo/CustomerInfo'
import * as Tip from './Tip/Tip'
import * as CompanyName from './CompanyName/CompanyName'
import { FulfillmentContextType } from '../FulfillmentProvider/FulfillmentProvider'
import { EventType, FormField, FormValue } from '../../types/form'
import { PreComputedTip } from '../../types/cart'
import { Customer as AuthedCustomer } from '../../auth/CustomerAuthContext'
import { TdsConfig } from '../../types/config'

export const formModules = [
  CustomerInfo,
  CheckoutDeliveryInfo,
  Tip,
  DynamicFormSection,
  CompanyName
]

export const whenNewCredit = ({
  then,
  otherwise = object().optional()
}: {
  then: BaseSchema
  otherwise?: BaseSchema
}) =>
  object().when(['paymentType', 'paymentTip'], {
    is: (paymentType: PaymentType, paymentTip: number) => {
      if (paymentType !== 'CREDIT') return false
      if (paymentTip > 0) return true
      return true
    },
    then,
    otherwise
  })

export const getValidationSchema = ({
  fulfillmentContext,
  formFields,
  useSpiForPayment,
  tdsConfig,
  cartAmount
}: {
  fulfillmentContext: FulfillmentContextType
  formFields: FormField[]
  useSpiForPayment: boolean
  tdsConfig: TdsConfig
  cartAmount: number
}) => {
  // To be refactored into their own form modules.
  const baseSchemaValidationSchema = {
    paymentType: string()
      .trim()
      .required('Required')
      .oneOf(['CREDIT', 'OTHER'], 'must be a valid payment type'),
    encryptedCard: useSpiForPayment
      ? object().optional()
      : whenNewCredit({
          // The validation only seems to work with string() even though this value is
          // actually an object. Note that the value itself should be opaque since it
          // came from the iframe so we really only care whether it is present or not.
          then: object().required('Required')
        }),
    isPaymentInfoValid: boolean().when(['paymentType'], {
      is: (paymentType: PaymentType) =>
        paymentType === 'CREDIT' && useSpiForPayment,
      then: boolean().oneOf([true], 'Payment info is not valid').required(),
      otherwise: boolean().optional()
    })
  }

  const validationSchema = formModules.reduce(
    (acc, formModule) => ({
      ...acc,
      ...(formModule.getValidationSchema
        ? formModule.getValidationSchema({
            fulfillmentContext,
            formFields,
            tdsConfig,
            cartAmount
          })
        : null)
    }),
    baseSchemaValidationSchema
  )

  return object().shape(validationSchema)
}

export interface ArgsForSubmit {
  paymentType: PaymentType
  ccInput: {
    cartGuid?: string
    customerGuid?: string
    customer: CustomerInfo.Customer
    newAddress?: {
      deliveryInfo: DeliveryInfo
    }
    newCardInput?: CardInput
    tipAmount: number
  }
  formValues: FormValue[]
  loyaltyEnroll: boolean | undefined
  taxExemptId?: string
  taxExemptState?: string
  companyName: string | undefined
  shouldLookupLoyalty: boolean
}

interface CardInput {
  encryptionKeyId: string
  encryptedCardDataString: string
  cardFirst6: string
  cardLast4: string
  expMonth: string
  expYear: string
  zipCode: string
}

export const getArgsForSubmit = ({
  fulfillmentContext,
  values,
  eventType
}: {
  fulfillmentContext: FulfillmentContextType
  values: CheckoutFormValues
  eventType: EventType
}): ArgsForSubmit => {
  let { paymentType } = values

  const customer = CustomerInfo.getArgsForSubmit({ values })

  const deliveryInfoArgs = CheckoutDeliveryInfo.getArgsForSubmit({
    fulfillmentContext,
    values
  })

  const tipArgs = Tip.getArgsForSubmit({
    values
  })

  const formArgs = DynamicFormSection.getArgsForSubmit(eventType, values)

  return {
    paymentType: paymentType,
    ccInput: {
      ...customer,
      ...deliveryInfoArgs,
      ...tipArgs,
      newCardInput: paymentType === 'CREDIT' ? values.encryptedCard : undefined
    },
    formValues: formArgs,
    loyaltyEnroll: values.loyaltyEnroll,
    companyName: values.companyName,
    shouldLookupLoyalty: values.optedInToLoyaltyLookup
  }
}

export interface CheckoutFormValues {
  encryptedCard: CardInput | undefined
  paymentType: PaymentType
  paymentTip: string | number
  loyaltyEnroll: boolean | undefined
  companyName?: string
  preComputedTips?: PreComputedTip
  optedInToLoyaltyLookup: boolean
  isPaymentInfoValid: boolean
  customerFirstName?: string
  customerLastName?: string
  customerEmail?: string
  customerTel?: string
}

export interface InitialValuesArgs extends InitialCustomerValueArgs {
  fulfillmentContext: FulfillmentContextType
  formFields: FormField[]
  paymentType: PaymentType
  preComputedTips: PreComputedTip[] | undefined
  companyName: string | undefined
}

export interface InitialCustomerValueArgs {
  authedCustomer: AuthedCustomer | undefined
  customer: Customer | undefined
}

export const getInitialValues = (
  args: InitialValuesArgs
): CheckoutFormValues => {
  const { paymentType } = args
  const initialValues: CheckoutFormValues = {
    encryptedCard: undefined,
    paymentType,
    paymentTip: '',
    optedInToLoyaltyLookup: false,
    isPaymentInfoValid: false,
    loyaltyEnroll: false
  }

  return formModules.reduce(
    (acc, formModule) => ({
      ...acc,
      ...(formModule.getInitialValues
        ? formModule.getInitialValues(args)
        : null),
      gcNumber: ''
    }),
    initialValues
  )
}
