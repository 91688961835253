import React from 'react'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { RecentOrdersContent } from './RecentOrdersContent'

export const SeeMoreRecentOrdersModal = ({
  isOpen,
  onClose,
  orders,
  setPreviewOrder
}) => {
  return (
    <Modal
      size={'xl'}
      isOpen={isOpen}
      onRequestClose={onClose}
      testId={'recent-orders-modal'}
    >
      <ModalHeader>Order again</ModalHeader>
      <ModalBody className={'mt-4 -mb-4'}>
        <RecentOrdersContent
          orders={orders}
          inModal
          setPreviewOrder={setPreviewOrder}
        />
      </ModalBody>
    </Modal>
  )
}
