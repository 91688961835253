import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import styles from './LoadingOverlay.module.css'

interface Props {
  isVisible: boolean
}

export const LoadingOverlay: React.FC<Props> = ({ isVisible }) => {
  if (!isVisible) return null

  return (
    <div className={styles.overlay}>
      <TailSpin
        wrapperClass='pr-8'
        color='#FFFFFF'
        width='80px'
        height='80px'
      />
    </div>
  )
}
