import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import React, { useMemo } from 'react'
import { RecentOrder } from '../../../hooks/use-recent-orders'
import {
  getFormatedCreatedDate,
  getFormatedItemCount
} from './recent-order-utils'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { SelectionsTable } from '../../Cart/CartTable/CartTable'
import { CartButton } from '@local/do-secundo-cart-button/src'
import { Dollars } from '@local/do-secundo-dollars'
import cx from 'classnames'
import styles from '@local/do-secundo-modifiers/src/ModifiersFooter/ModifiersFooter.module.css'
import { useReorder } from '../../CartMutation/use-reorder'
import { alertError } from '../../../auth/alertUtils'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'
import { useGetCart } from '../../CartQuery/CartQuery'
import { useSentry } from 'banquet-runtime-modules'

export type PreviewRecentOrderModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  order: RecentOrder
  onReorder?: () => void
  onBack: () => void
  showBackButton: boolean
}

export const PreviewRecentOrderModal = ({
  isOpen,
  onRequestClose,
  order,
  onReorder,
  onBack,
  showBackButton
}: PreviewRecentOrderModalProps) => {
  const [loading, setLoading] = React.useState(false)

  const reorder = useReorder()

  const { cartGuid } = useGetCart()
  const { cartIsEmpty } = useGetCart()

  const { captureException } = useSentry()

  const handleReorder = useMemo(() => {
    return () => {
      setLoading(true)

      // start a new cart if the cart is empty
      const cartToUpdate = cartIsEmpty ? undefined : cartGuid

      reorder(order.orderGuid, cartToUpdate)
        .catch((err) => {
          captureException(err)
          alertError('Failed to add items to cart.')
        })
        .finally(() => {
          onReorder && onReorder()
          onRequestClose()
          setLoading(false)
        })
    }
  }, [cartIsEmpty, cartGuid, reorder])

  const { restaurantInfo } = useRestaurant()

  return (
    <Modal
      size={'xxl'}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      testId={'recent-order-preview'}
    >
      <ModalHeader>
        {showBackButton && (
          <IconButton
            testId={'recent-order-preview-back-icon'}
            className={'-ml-3 mr-2'}
            icon={<ChevronLeftIcon aria-label='back' />}
            iconColor='secondary'
            onClick={onBack}
          />
        )}
        {getFormatedItemCount(order)}
      </ModalHeader>
      <ModalBody>
        Ordered on {getFormatedCreatedDate(order, restaurantInfo?.timeZoneId)}
        <hr className={'my-4'} />
        <UnavailableItemsWarning order={order} />
        <div className={'text-default'}></div>
        <SelectionsTable selections={order.selections} />
      </ModalBody>
      <ModalFooter>
        <div className={cx(styles.buttonWrapper, '-m-3')}>
          <CartButton
            type={'button'}
            left={<>Add to cart</>}
            right={<Dollars amount={order.itemSubtotal} />}
            onClick={handleReorder}
            loading={loading}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

const UnavailableItemsWarning = ({ order }: { order: RecentOrder }) => {
  if (order.unavailableItems.length === 0) {
    return null
  }

  const groupedUnavailableItems = Object.entries(
    order.unavailableItems.reduce((acc, item) => {
      if (acc[item]) {
        acc[item] += 1
      } else {
        acc[item] = 1
      }
      return acc
    }, {} as Record<string, number>)
  )

  const unavailableItemsLen = groupedUnavailableItems.length

  return (
    <Alert className={'w-full mb-4'} variant={'warning'}>
      {unavailableItemsLen} item
      {unavailableItemsLen === 1 ? '' : 's'} from your original order{' '}
      {unavailableItemsLen === 1 ? 'is' : 'are'} no longer available:{' '}
      {groupedUnavailableItems.map(([name, _]) => name).join(', ')}
    </Alert>
  )
}
