import React, { useState } from 'react'
import cx from 'classnames'
import { ErrorComponent } from '@local/do-secundo-error'
import { Progress } from '@local/do-secundo-progress'
import { RestaurantAddress } from '../RestaurantAddress/RestaurantAddress'
import styles from './RestaurantInfo.module.css'
import { FulfillmentDisplay } from '../FulfillmentDisplay/FulfillmentDisplay'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { FulfillmentSelectorModal } from '../FulfillmentSelectorModal/FulfillmentSelectorModal'
import { useLocation } from 'react-router-dom'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'

interface Props {
  orderingAvailable: boolean
  showDeliveryAddress?: boolean
  header?: string
}

export const RestaurantInfo = ({
  orderingAvailable,
  showDeliveryAddress = false,
  header
}: Props) => {
  const location = useLocation()
  const { loading, error, restaurantInfo } = useRestaurant()
  const { customLocationAddress, diningOptionBehavior } = useFulfillment()

  const [showFulfillmentModal, setShowFulfillmentModal] = useState(false)

  if (loading || !restaurantInfo) return <Progress />
  if (error) {
    return (
      <div className='pt-2'>
        <ErrorComponent error={error} isActionable={false} />
      </div>
    )
  }

  const displayAddress =
    customLocationAddress && diningOptionBehavior === 'TAKE_OUT'
      ? customLocationAddress
      : restaurantInfo.address

  return (
    <div className={styles.info} role='group' aria-label='restaurant info'>
      {header && (
        <h3
          data-testid={'header'}
          className='mx-0 type-headline-3 text-default sm:mt-10'
        >
          {header}
        </h3>
      )}

      <p className={cx(styles.name, 'type-large')}>{restaurantInfo.name}</p>
      <RestaurantAddress {...displayAddress} />
      {orderingAvailable ? (
        <>
          <button
            data-testid={`fulfillment-selector-${location.pathname}`}
            className={`border-0 bg-transparent block pl-0 my-1 ${
              orderingAvailable ? 'cursor-pointer' : 'cursor-text'
            }`}
            disabled={!orderingAvailable}
            onClick={() => setShowFulfillmentModal(true)}
          >
            <FulfillmentDisplay
              showDeliveryAddress={showDeliveryAddress}
              editable={orderingAvailable}
              stylizedForLink={true}
            />
          </button>
          {showFulfillmentModal && (
            <FulfillmentSelectorModal
              onClose={() => setShowFulfillmentModal(false)}
            />
          )}
        </>
      ) : null}
    </div>
  )
}
