import React from 'react'
import cx from 'classnames'

export const LegalCopy = ({ className }: { className?: string }) => {
  return (
    <p
      className={cx('type-caption mx-1 mt-5', className)}
      data-testid='legal-links'
      role='group'
      aria-label='legal information'
    >
      By completing your order, you give Toast and this restaurant/restaurant
      group permission to send you informational messages (such as order updates
      and digital receipts). Consent to marketing is not a condition of
      purchase. Message & data rates may apply, msg frequency varies. Reply STOP
      to opt out. Subject to Toast's{' '}
      <a
        href={'https://pos.toasttab.com/terms-of-service/#diner-tos'}
        rel='noopener noreferrer'
        target='_blank'
        data-testid='link-terms-of-service'
        className='type-caption text-link'
      >
        Terms of Service
      </a>{' '}
      and{' '}
      <a
        href={'https://pos.toasttab.com/privacy'}
        rel='noopener noreferrer'
        target='_blank'
        data-testid='link-privacy'
        className='type-caption text-link'
      >
        Privacy Statement
      </a>{' '}
      and Merchant's Terms and Policies. Info for CA residents available{' '}
      <a
        href={'https://pos.toasttab.com/privacy#addendum-a'}
        rel='noopener noreferrer'
        target='_blank'
        data-testid='link-ca-addendum'
        className='type-caption text-link'
      >
        here
      </a>
      .
    </p>
  )
}
