import React from 'react'
import styles from '../../Menus/MenuItems/MenuItems.module.css'

import cx from 'classnames'
import { Link } from 'react-router-dom'
import { RecentOrder } from '../../../hooks/use-recent-orders'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { MenuItemPrice } from '@local/do-secundo-menu-item-price/src'
import {
  getDescription,
  getFormatedCreatedDate,
  getFormatedItemCount
} from './recent-order-utils'

export type RecentOrdersContentProps = {
  orders: RecentOrder[]
  inModal?: boolean
  setPreviewOrder: (order: RecentOrder) => void
}

export const RecentOrdersContent = ({
  orders,
  inModal,
  setPreviewOrder
}: RecentOrdersContentProps) => {
  return (
    <>
      <ul
        data-testid='recent-orders'
        className={cx(
          inModal ? styles.recentOrdersModal : styles.menuItems,
          styles.verticalLayout
        )}
      >
        {orders.map((order) => (
          <RecentOrderCard
            key={order.orderGuid}
            order={order}
            inModal={!!inModal}
            setPreviewOrder={setPreviewOrder}
          />
        ))}
      </ul>
    </>
  )
}

const RecentOrderCard = ({
  order,
  inModal,
  setPreviewOrder
}: {
  order: RecentOrder
  inModal: boolean
  setPreviewOrder: (order: RecentOrder) => void
}) => {
  const { restaurantInfo } = useRestaurant()

  return (
    <>
      <li
        data-testid='recent-order'
        key={order.orderGuid}
        className={cx(
          inModal ? styles.recentOrderInModal : styles.menuItem,
          'type-default'
        )}
      >
        <Link
          data-testid='recent-order-button'
          className={styles.menuItemLink}
          to={{}}
          onClick={() => {
            setPreviewOrder(order)
          }}
        >
          <div data-testid='recent-order-content' className={styles.content}>
            <span
              data-testid='recent-order-title'
              className={cx(styles.name, 'font-semibold type-default')}
              itemProp={'name'}
            >
              {getFormatedItemCount(order)} ordered on{' '}
              {getFormatedCreatedDate(order, restaurantInfo?.timeZoneId)}
            </span>
            <p
              data-testid='recent-order-description'
              className={cx(styles.description, 'type-subhead')}
              itemProp={'description'}
            >
              {getDescription(order)}
            </p>
            <div className='space-x-2'>
              <MenuItemPrice price={order.itemSubtotal} />
            </div>
          </div>
        </Link>
      </li>
    </>
  )
}
