import React from 'react'
import { string } from 'yup'
import { TelephoneField } from '@toasttab/buffet-pui-forms'
import { useCustomerState } from '../../../api/customer'
import { Cart } from '../../../types/cart'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { useFormikContext } from 'formik'
import { CompanyName } from '../CompanyName/CompanyName'
import { Fieldset } from '@local/do-secundo-fieldset'
import { CheckoutFormValues, InitialCustomerValueArgs } from '../utils'
import { useCustomerAuth } from '../../../auth/CustomerAuthContext'
import { CompletedInfo } from './CompletedInfo'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { useEffectOnChange } from '../../../hooks/util'

export type CustomerInfoProps = {
  cart: Cart
  customer: Customer
  companyName: string | undefined
  setEditingAccount: (editing: boolean) => void
}

export const CustomerInfo = (props: CustomerInfoProps) => {
  return (
    <Fieldset label='Your Information'>
      <Component {...props} />
      <CompanyName {...props} />
    </Fieldset>
  )
}

const Component = ({
  customer,
  cart,
  companyName,
  setEditingAccount
}: {
  customer: Customer
  cart: Cart
  companyName: string | undefined
  setEditingAccount: (editing: boolean) => void
}) => {
  const { setFieldValue, setValues, values } =
    useFormikContext<CheckoutFormValues>()
  const { onChange } = useCustomerState(cart, customer)

  const {
    loadingCustomer,
    isLoggedIn,
    customer: authedCustomer
  } = useCustomerAuth()

  useEffectOnChange(() => {
    if (authedCustomer) {
      setValues({
        ...values,
        ...getInitialValues({ customer, authedCustomer })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authedCustomer])

  if (loadingCustomer) {
    return <Loading variant={LoadingVariant.SECONDARY} />
  }

  if (isLoggedIn) {
    return (
      <div>
        <CompletedInfo />
        <Button
          className={'-ml-4'}
          variant={'link'}
          onClick={() => setEditingAccount(true)}
        >
          Edit Toast account
        </Button>
      </div>
    )
  } else {
    return (
      <div>
        <TextInput
          required
          containerClassName='mb-4'
          autoComplete='section-customer given-name'
          testId='customer_first_name'
          id='customer_first_name'
          label='First name'
          name='customerFirstName'
          type='text'
          autoFocus
          value={customer.firstName}
          onChange={(event) => {
            setFieldValue('customerFirstName', event.target.value)
            setFieldValue('optedInToLoyaltyLookup', false)
            onChange(
              { ...customer, firstName: event.target.value },
              cart,
              companyName
            )
          }}
        />
        <TextInput
          required
          containerClassName='mb-4'
          autoComplete='section-customer family-name'
          testId='customer_last_name'
          id='customer_last_name'
          label='Last name'
          name='customerLastName'
          type='text'
          value={customer.lastName}
          onChange={(event) => {
            setFieldValue('customerLastName', event.target.value)
            onChange(
              { ...customer, lastName: event.target.value },
              cart,
              companyName
            )
          }}
        />
        <TextInput
          required
          containerClassName='mb-4'
          autoComplete='section-customer email'
          testId='customer_email'
          id='customer_email'
          label='Email'
          name='customerEmail'
          type='email'
          value={customer.email}
          onChange={(event) => {
            setFieldValue('customerEmail', event.target.value)
            onChange(
              { ...customer, email: event.target.value },
              cart,
              companyName
            )
          }}
        />
        <TelephoneField
          required
          containerClassName='mb-4'
          allowEmptyFormatting={false}
          autoComplete='section-customer tel-national'
          testId='customer_tel'
          id='customer_tel'
          label='Phone'
          name='customerTel'
          type='tel'
          helperText='By providing a mobile number, you give Toast permission to contact you
      using automated text messages to provide transactional messages such as
      order status updates.'
          value={customer.phone}
          onChange={(event) => {
            setFieldValue('customerTel', event.value)
            onChange({ ...customer, phone: event.value }, cart, companyName)
          }}
        />
      </div>
    )
  }
}

export const getInitialValues = ({
  customer,
  authedCustomer
}: InitialCustomerValueArgs) =>
  authedCustomer
    ? {
        customerFirstName: authedCustomer.firstName ?? '',
        customerLastName: authedCustomer.lastName ?? '',
        customerEmail: authedCustomer.email ?? '',
        customerTel: stripCountryCode(authedCustomer.phone)
      }
    : {
        customerFirstName: customer?.firstName ?? '',
        customerLastName: customer?.lastName ?? '',
        customerEmail: customer?.email ?? '',
        customerTel: stripCountryCode(customer?.phone)
      }

const stripCountryCode = (phone: string | undefined | null) => {
  if (!phone) {
    return ''
  }

  const stripped = phone.replace(/\D/g, '')

  if (stripped.length > 10) {
    return stripped.slice(-10)
  } else {
    return phone
  }
}

export interface Customer {
  email: string
  firstName: string
  lastName: string
  phone: string
}

export interface UpdateCustomerRequest extends Customer {
  companyName?: string
}

export const getArgsForSubmit = ({ values }): { customer: Customer } => ({
  customer: {
    email: values.customerEmail,
    firstName: values.customerFirstName,
    lastName: values.customerLastName,
    phone: values.customerTel.toString()
  }
})

export const getValidationSchema = () => ({
  customerFirstName: string().trim().required('Required'),
  customerLastName: string().trim().required('Required'),
  customerEmail: string()
    .trim()
    .email('Must be valid email')
    .required('Required'),
  customerTel: string()
    .trim()
    .matches(/^\d{10}$/, 'Must be a 10 digit number')
    .required('Required')
})
