import useScript from 'react-script-hook'
import { FF, useFlag } from '@local/do-secundo-feature-flag'
import { spiSdkSrc } from '../../api/util'
import { SpiSdk } from './types'
import { useSentry } from 'banquet-runtime-modules'
import { useEffect } from 'react'
import { useTracker } from '../../analytics/tracker'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

declare global {
  interface Window {
    Toast: SpiSdk
  }
}

/** SPI is not for intl customers, but COO is also not yet available outside of the US. */
const isIntlRestaurant = false

export const useSpiSdk = () => {
  const spiFeatureEnabled = useFlag(FF.NVS_COO_SPI)
  const spiEnabled = !isIntlRestaurant && spiFeatureEnabled

  const { restaurantGuid } = useRestaurant()

  const { captureException } = useSentry()
  const tracker = useTracker()

  const [loading, error] = useScript({
    src: spiEnabled ? spiSdkSrc : null
  })

  useEffect(() => {
    if (!loading && error) {
      captureException(
        new Error(
          `SPI - Failed to load SDK: ${error.message ?? JSON.stringify(error)}`
        )
      )
      tracker.trackSpiScriptLoadFailure(restaurantGuid)
    } else if (!loading && !error) {
      tracker.trackSpiScriptLoadSuccess(restaurantGuid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading])

  return !loading && !error && typeof window !== 'undefined'
    ? window.Toast
    : undefined
}
