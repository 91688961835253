import React, { useCallback } from 'react'
import { usePayment } from '../../account/PaymentContext'

import styles from './CheckoutPage.module.css'

import { Image } from '../Image/Image'
import cx from 'classnames'
import { CheckoutMode, useCustomerAuth } from '../../auth/CustomerAuthContext'
import { useGetCart } from '../CartQuery/CartQuery'

export type SelectCheckoutModeProps = {
  setSelectedCheckoutMode: (value: boolean) => void
  setIsAuthModalOpen: (value: boolean) => void
}

export const SelectCheckoutMode = (props: SelectCheckoutModeProps) => {
  return (
    <div>
      <ToastCheckoutButton {...props} />
      <GuestCheckoutButton {...props} />
    </div>
  )
}

const ToastCheckoutButton = ({
  setIsAuthModalOpen
}: SelectCheckoutModeProps) => {
  const { cartGuid } = useGetCart()

  if (!cartGuid) {
    return null
  }

  return (
    <div
      className={cx(styles.checkoutMode, 'mb-4')}
      data-testid='expressCheckoutButton'
    >
      <button
        type='button'
        className={styles.toastCheckout}
        onClick={() => setIsAuthModalOpen(true)}
        aria-expanded='false'
        aria-controls='checkoutSection'
      >
        <div className={styles.text}>
          <Image
            alt='Toast logo'
            aria-hidden='true'
            path='icons/toast-logo-filled.svg'
          />{' '}
          Express checkout with Toast
        </div>
      </button>
    </div>
  )
}

const GuestCheckoutButton = ({
  setSelectedCheckoutMode
}: SelectCheckoutModeProps) => {
  const { setCheckoutMode } = useCustomerAuth()

  const selectMode = (mode: CheckoutMode) => {
    setCheckoutMode(mode)
    setSelectedCheckoutMode(true)
  }

  const { setPaymentOption } = usePayment()
  const onButtonClick = useCallback(() => {
    selectMode(CheckoutMode.Guest)
    setPaymentOption(null)
  }, [selectMode, setPaymentOption])

  return (
    <div className={styles.checkoutMode}>
      <button
        type='button'
        data-testid='guestCheckoutButton'
        className={styles.borderedGuestCheckout}
        onClick={onButtonClick}
        aria-expanded='false'
        aria-controls='checkoutSection'
      >
        <div className={styles.text}>Checkout as a guest</div>
      </button>
    </div>
  )
}
