import React from 'react'
import { BrowserRouter, Route, MemoryRouter, Routes } from 'react-router-dom'
import { OOGlobalsConsumer } from '@local/do-secundo-oo-globals-provider'
import { RestaurantPage } from '../RestaurantPage/RestaurantPage'
import { RestaurantProvider } from '@local/do-secundo-restaurant-provider'
import AllPagesTag from '../../../packages/ad-tracking/AllPagesTag'
import { AuthProvider } from '@toasttab/do-secundo-guest-authentication'
import { apiHost } from '../../api/util'
import { CustomerAuthContextProviderWrapper } from '../../auth/CustomerAuthContextProviderWrapper'

export const OnlineOrderingApp: React.FC<{ useMemoryRouter: boolean }> = ({
  useMemoryRouter = false
}) => {
  const startPath = window['CI_START_PATH'] || '/'

  const content = (
    <Routes>
      <Route path='/menu/:fastLinkName/*' element={<RestaurantPage />} />
      <Route path='/*' element={<RestaurantPage />} />
    </Routes>
  )

  return (
    <AuthProvider gatewayOrigin={apiHost}>
      <OOGlobalsConsumer>
        {({ restaurantGuid, routerBasename }) => (
          <RestaurantProvider restaurantGuid={restaurantGuid}>
            <CustomerAuthContextProviderWrapper>
              <AllPagesTag />
              {!useMemoryRouter && (
                <BrowserRouter basename={routerBasename}>
                  {content}
                </BrowserRouter>
              )}
              {useMemoryRouter && (
                <MemoryRouter initialEntries={[startPath]} initialIndex={0}>
                  {content}
                </MemoryRouter>
              )}
            </CustomerAuthContextProviderWrapper>
          </RestaurantProvider>
        )}
      </OOGlobalsConsumer>
    </AuthProvider>
  )
}
