import { useQuery } from 'react-query'
import { DateTime } from 'luxon'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { getFulfillmentTimes } from '../api/restaurant'
import {
  DiningOptionFulfillmentScheduleApi,
  FutureFulfillmentDateApi,
  FutureFulfillmentSlot,
  FutureFulfillmentType,
  FutureScheduleDate,
  MenuFulfillmentResponse
} from '../types/fulfillment'
import { DiningOptionBehavior } from '../types/cart'
import { useFulfillment } from '../components/FulfillmentProvider/FulfillmentProvider'
import { useCart } from '@local/do-secundo-cart-provider/src'

export const DINING_OPTION_STRINGS: Record<DiningOptionBehavior, string> = {
  DELIVERY: 'Delivery',
  TAKE_OUT: 'Pickup',
  DINE_IN: 'Dine In'
}

export interface DiningOptionFulfillmentData {
  guid: string
  behavior: DiningOptionBehavior
  deliveryProvider?: string
  futureSchedule: { dates: FutureScheduleDate[] }
}

const getBehavior = (
  diningOption: DiningOptionFulfillmentScheduleApi
): DiningOptionBehavior => {
  return diningOption.diningOptionBehavior || 'TAKE_OUT'
}

export const useDiningOptions = (
  fastLinkNameProvided: string | undefined = undefined,
  getForStandardSite: boolean = false
) => {
  const { ooConfig } = useRestaurant()
  const { fastLinkName = fastLinkNameProvided, deliveryInfo } = useFulfillment()
  const { cartGuid } = useCart()

  const fastLinkNameToUse = getForStandardSite ? undefined : fastLinkName

  const startDate = DateTime.now().toISODate()
  const endDate = DateTime.fromISO(startDate)
    .plus({ days: ooConfig?.scheduledOrderMaxDays || 0 })
    .toISODate()!!

  const fulfillmentQuery = useQuery<MenuFulfillmentResponse, Response>(
    [
      'oo-fulfillment',
      startDate,
      endDate,
      fastLinkNameToUse,
      deliveryInfo,
      cartGuid
    ],
    () =>
      getFulfillmentTimes({
        startDate,
        endDate,
        fastLinkName: fastLinkNameToUse,
        deliveryInfo: deliveryInfo,
        cartGuid: cartGuid
      }),
    {
      staleTime: 60 * 1000,
      enabled: !!ooConfig
    }
  )

  return {
    isLoading: fulfillmentQuery.isLoading,
    error: fulfillmentQuery.error,
    refetch: fulfillmentQuery.refetch,
    data: fulfillmentQuery.data?.fulfillment
      ?.filter((diningOption) => {
        if (getBehavior(diningOption) === 'TAKE_OUT') {
          return ooConfig?.takeoutEnabled
        } else {
          return ooConfig?.deliveryEnabled
        }
      })
      ?.map((diningOption) => {
        return {
          guid: diningOption.diningOptionGuid,
          behavior: getBehavior(diningOption),
          deliveryProvider: diningOption.deliveryProvider,
          futureSchedule: mapToFutureSchedule(
            diningOption.futureFulfillment.fulfillmentDates
          )
        } as DiningOptionFulfillmentData
      }),
    displayName: fulfillmentQuery.data?.displayName,
    displayAvailability: fulfillmentQuery.data?.availability,
    fastLinkNotFound: fulfillmentQuery.data?.fastLinkNotFound || false,
    independent: fulfillmentQuery.data?.independent || false
  }
}

const mapToFutureSchedule = (
  fulfillmentDates: FutureFulfillmentDateApi[]
): { dates: FutureScheduleDate[] } => {
  const dates: FutureScheduleDate[] = fulfillmentDates.map((dateObject) => {
    const timesAndGaps: FutureFulfillmentSlot[] = dateObject.times.map(
      (timeSlot) => ({
        type: timeSlot.serviceGap
          ? FutureFulfillmentType.ServiceGap
          : FutureFulfillmentType.Time,
        time: timeSlot.time
      })
    )

    return {
      date: dateObject.date,
      times: timesAndGaps.filter(
        (time: FutureFulfillmentSlot) =>
          time.type === FutureFulfillmentType.Time
      )
    }
  })
  return {
    dates
  }
}
