import trimEnd from 'lodash/trimEnd'
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import envFromHostname from '@toasttab/env-from-hostname'
import { getHttpLink } from './links/http-link'
import { getRetryLinkFactory } from './links/retry-link'
import PossibleTypesResult from '../apollo/generated/PossibleTypes'
import { geolocationResolvers } from './geolocation/geolocation-resolvers'
import { managementGroupResolvers } from './management-group/management-group-resolvers'
import { OOGlobals } from '@local/do-secundo-oo-globals-provider'
import { setContext } from '@apollo/client/link/context'
import { getAuthenticationHeader } from '@toasttab/do-secundo-guest-authentication'

const dataIdFromObjectMap = {
  _default: (object) => object.guid || null
}

export const dataIdFromObject = (object) => {
  const idMapper =
    dataIdFromObjectMap[object.__typename] || dataIdFromObjectMap._default
  return idMapper(object)
}

export const cache = new InMemoryCache({
  dataIdFromObject,
  possibleTypes: PossibleTypesResult.possibleTypes
})

const enableRetries = false
const env = envFromHostname(window.location.hostname)
const prefix =
  env === 'prod'
    ? 'ws-api'
    : env === 'preprod'
    ? 'ws-preprod-api.eng'
    : 'ws-dev-api.eng'

export const resolvers = [geolocationResolvers, managementGroupResolvers]

export type Config = {
  cache: InMemoryCache
  GATEWAY_BASE_URI: string
  BFF_BASE_URI: string
  RESTAURANT_GUID: string
  client?: ApolloClient<any>
}

const linkFactories = [getRetryLinkFactory(enableRetries), getHttpLink]

const getApolloClientOptionsLink = (
  apolloLinks: any,
  config: Config,
  ooGlobals: OOGlobals
) => {
  const link = ApolloLink.from(apolloLinks.map((fn) => fn(config)))

  const sessionToken = ooGlobals.session?.id
  const sessionLink = sessionToken
    ? securityTokenHeaderLinkFactory(sessionToken)
    : null

  const authLink = getGiaAuthLink()
  const secLink = sessionLink ? authLink.concat(sessionLink) : authLink
  return secLink.concat(link)
}

export const getGiaAuthLink = () =>
  setContext(async (_, { headers: originalHeaders }) => {
    const authHeader = await getAuthenticationHeader()
    const headers = authHeader ? { [authHeader.key]: authHeader.value } : {}

    return {
      headers: {
        ...originalHeaders,
        ...headers
      }
    }
  })

export const securityTokenHeaderLinkFactory = (sessionToken: string) =>
  setContext((_, { headers: originalHeaders }) => {
    return {
      headers: {
        ...originalHeaders,
        'Toast-Session-ID': sessionToken
      }
    }
  })

/**
 * Returns apolloClient for use with PasswordlessAuthentication
 * @returns ApolloClient
 */
export const getApolloClient = (ooGlobals: OOGlobals) => {
  const gatewayBaseUri = trimEnd(
    process.env.GATEWAY_BASE_URI ||
      ooGlobals.gatewayBaseUri ||
      `https://${prefix}.toasttab.com`,
    '/'
  )

  const config: Config = {
    cache: cache,
    GATEWAY_BASE_URI: gatewayBaseUri,
    BFF_BASE_URI: trimEnd(
      process.env.BFF_BASE_URI || `${gatewayBaseUri}/do-federated-gateway/v1`,
      '/'
    ),
    RESTAURANT_GUID: ooGlobals.restaurantGuid || ''
  }

  const apolloClientOptions = {
    link: getApolloClientOptionsLink(linkFactories, config, ooGlobals),
    cache: config.cache,
    resolvers,
    name: 'corn-catering-oo-web',
    version: process.env.PKG_VERSION
  }

  config.client = new ApolloClient(apolloClientOptions)

  return config.client
}
