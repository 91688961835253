import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, ButtonVariant } from '@local/do-secundo-button'

export const BackToMenuButton = ({ getRestaurantPath }) => (
  <div className='w-full flex justify-center mb-10'>
    <Link to={getRestaurantPath()}>
      <Button data-testid='back-to-menu-button' variant={ButtonVariant.LINK}>
        Back to menu
      </Button>
    </Link>
  </div>
)

BackToMenuButton.propTypes = {
  getRestaurantPath: PropTypes.func.isRequired
}
