import React, { useState } from 'react'
import { MenuHeader } from '../../Menus/MenuHeader'
import styles from '../../Menus/MenuGroups/MenuGroups.module.css'
import { RecentOrdersContent } from './RecentOrdersContent'
import cx from 'classnames'
import { RecentOrder, useRecentOrders } from '../../../hooks/use-recent-orders'
import { useGetCart } from '../../CartQuery/CartQuery'
import { useCustomerAuth } from '../../../auth/CustomerAuthContext'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SeeMoreRecentOrdersModal } from './SeeMoreRecentOrdersModal'
import { PreviewRecentOrderModal } from './PreviewRecentOrderModal'

export const RecentOrders = () => {
  const { isLoggedIn } = useCustomerAuth()
  const { data } = useRecentOrders()
  const { cart, cartIsEmpty } = useGetCart()

  const [seeMore, setSeeMore] = useState(false)
  const [previewOrder, setPreviewOrder] = useState<RecentOrder | null>(null)

  if (!isLoggedIn) {
    return null
  }

  const alreadyAddedOrders = new Set(
    cart?.metadata?.reorderedFromOrderGuids || []
  )

  const recentOrders = cartIsEmpty
    ? data?.orders
    : data?.orders.filter((o) => !alreadyAddedOrders.has(o.orderGuid))

  if (!recentOrders?.length) {
    return null
  }

  return (
    <>
      <MenuHeader name={'Order again'} futureAvailability={[]} />
      <ul className={cx(styles.menuGroup, 'pt-2')}>
        <RecentOrdersContent
          orders={recentOrders.slice(0, 4)}
          setPreviewOrder={setPreviewOrder}
        />
      </ul>
      <div className={'mt-4'}>
        {recentOrders.length > 4 && (
          <Button variant={'link'} onClick={() => setSeeMore(true)}>
            See more
          </Button>
        )}
      </div>
      {previewOrder ? (
        <PreviewRecentOrderModal
          isOpen
          onRequestClose={() => {
            setPreviewOrder(null)
            setSeeMore(false)
          }}
          order={previewOrder}
          onBack={() => setPreviewOrder(null)}
          showBackButton={seeMore}
        />
      ) : (
        <SeeMoreRecentOrdersModal
          isOpen={seeMore}
          onClose={() => setSeeMore(false)}
          orders={recentOrders}
          setPreviewOrder={setPreviewOrder}
        />
      )}
    </>
  )
}
