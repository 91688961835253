import { DeliveryInfo } from '../types/orders'
import { domain, post } from './util'

export type UnavailabilityReason = {
  reasonCode: string
  reasonMsg: string
}

export type DeliveryValidationResponse = {
  valid: boolean
  isOutOfBounds: boolean
  isTdsInvalid: boolean
  tdsUnavailabilityReasons: UnavailabilityReason[]
  tdsEarliestDate?: string
  tdsEarliestTime?: string
}

export const validateDeliveryAddress = (request: {
  address: DeliveryInfo
  cartGuid: string | undefined
}): Promise<DeliveryValidationResponse> => {
  return post(
    `https://${domain}/catering/v2/public/validation/delivery`,
    request
  ).then((response) => response.json())
}
