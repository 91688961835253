import React, { useEffect } from 'react'
import * as CustomerInfo from './CustomerInfo/CustomerInfo'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  getArgsForSubmit,
  getInitialValues,
  getValidationSchema
} from './utils'
import { useCart } from '@local/do-secundo-cart-provider/src'
import { CheckoutFormBody } from './CheckoutFormBody'
import { useGetCart } from '../CartQuery/CartQuery'
import { useCartValidator } from '../Cart/Validation/CartValidation'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { ErrorComponent } from '@local/do-secundo-error'
import { Form, Formik } from 'formik'
import {
  getCheckAmountExcludingTax,
  getCheckTotal
} from '../../utils/cart-helpers'
import styles from './CheckoutForm.module.css'
import { CartCheckoutFields } from './CartCheckoutFields'
import { FF, useFlag } from '@local/do-secundo-feature-flag/src'
import { CheckoutMode, useCustomerAuth } from '../../auth/CustomerAuthContext'
import { SelectCheckoutMode } from '../CheckoutPage/SelectCheckoutMode'
import { PayLaterOption } from '../../types/config'
import { useHandlePlaceOrder } from '../CheckoutPage/utils'
import { useSentry } from 'banquet-runtime-modules'
import { LoadingOverlay } from '@local/do-secundo-loading/src/Loading/LoadingOverlay'

interface Props {
  setEditingAccount: (editing: boolean) => void
  setIsAuthModalOpen: (value: boolean) => void
  setSelectedCheckoutMode: (value: boolean) => void
  selectedCheckoutMode: boolean
  isAuthModalOpen: boolean
}

export const CheckoutForm: React.FC<Props> = ({
  setEditingAccount,
  setIsAuthModalOpen,
  setSelectedCheckoutMode,
  selectedCheckoutMode,
  isAuthModalOpen
}) => {
  const pwLessAuthEnabled = useFlag(FF.NVS_COO_PWLESS_AUTH)
  const spiEnabled = useFlag(FF.NVS_COO_SPI)

  const { captureException } = useSentry()

  const {
    applicableConfigs,
    applicableConfigsLoading,
    applicableConfigsError
  } = useRestaurant()
  const { eventType, payLaterOption, tdsConfig } = applicableConfigs

  const {
    loadingCustomer,
    checkoutMode,
    customer: authedCustomer
  } = useCustomerAuth()

  useEffect(() => {
    if (!loadingCustomer && checkoutMode === CheckoutMode.Toast) {
      setSelectedCheckoutMode(true)
    }
  }, [loadingCustomer, checkoutMode])

  const fulfillmentContext = useFulfillment()
  const { cart } = useGetCart()
  const { cartValid } = useCartValidator(cart)
  const { willEarnLoyalty, setWillEarnLoyalty } = useCart()

  const { handlePlaceOrder, confirmOrderError } = useHandlePlaceOrder()

  if (
    fulfillmentContext.loading ||
    (!eventType && applicableConfigsLoading) ||
    !cart ||
    (loadingCustomer && !isAuthModalOpen)
  ) {
    return <Loading variant={LoadingVariant.SECONDARY} />
  }

  if (fulfillmentContext.error || (!eventType && applicableConfigsError)) {
    return (
      <ErrorComponent error={fulfillmentContext.error} isActionable={false} />
    )
  }

  // - If TDS is in play for this order, always require a cc payment
  // - Else if payLaterRequired, always set payment type to OTHER to prevent cc form
  // - Otherwise, only if the check total is >0, set payment type to CREDIT
  const payLaterRequired = payLaterOption === PayLaterOption.REQUIRE
  const paymentType =
    tdsConfig?.enabled || (!payLaterRequired && getCheckTotal(cart) > 0)
      ? 'CREDIT'
      : 'OTHER'

  const initialValues = getInitialValues({
    fulfillmentContext,
    formFields: eventType?.formFields || [],
    authedCustomer: authedCustomer,
    customer: cart?.order.checks[0].customer,
    paymentType,
    preComputedTips: cart?.allowTipping ? cart?.preComputedTips : undefined,
    companyName: cart?.metadata?.companyName || ''
  })

  const validationSchema = getValidationSchema({
    fulfillmentContext,
    formFields: eventType?.formFields || [],
    useSpiForPayment: spiEnabled,
    tdsConfig: tdsConfig ?? { enabled: false },
    cartAmount: getCheckAmountExcludingTax(cart)
  })

  // See this regarding custom validate prop instead of validationSchema so
  // that we can pass custom context to yup.
  // https://github.com/jaredpalmer/formik/issues/1359
  return (
    <Formik
      enableReinitialize
      data-testid='checkout-form'
      initialValues={initialValues}
      validationSchema={validationSchema}
      initialTouched={{ paymentTip: true }} //shows the tip error on page mount if one exists
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const submitArgs = getArgsForSubmit({
            fulfillmentContext,
            values,
            eventType: eventType!!
          })

          // since we enroll loyalty asynchronously, we will use this to show
          // the confirmation before applied loyalty appears on the order
          setWillEarnLoyalty(
            willEarnLoyalty || Boolean(submitArgs.loyaltyEnroll)
          )

          await handlePlaceOrder(submitArgs)
        } catch (e) {
          console.warn('onSubmit', e)
          const logError =
            e instanceof Error
              ? e
              : new Error(`Error placing order ${JSON.stringify(e)}`)
          captureException(logError)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ values, isSubmitting, isValid: isFormValid, handleSubmit }) => {
        const customer = CustomerInfo.getArgsForSubmit({ values }).customer
        return (
          <>
            {pwLessAuthEnabled &&
            !selectedCheckoutMode &&
            checkoutMode !== CheckoutMode.Toast ? (
              <>
                <CartCheckoutFields
                  values={values}
                  customer={customer}
                  cart={cart}
                  showTip={false}
                />
                <SelectCheckoutMode
                  setIsAuthModalOpen={setIsAuthModalOpen}
                  setSelectedCheckoutMode={setSelectedCheckoutMode}
                />
              </>
            ) : (
              <Form className={styles.form} onSubmit={handleSubmit}>
                <LoadingOverlay isVisible={isSubmitting} />
                <CheckoutFormBody
                  cart={cart}
                  eventType={eventType}
                  handleSubmit={handleSubmit}
                  customer={CustomerInfo.getArgsForSubmit({ values }).customer}
                  values={values}
                  isSubmitting={isSubmitting}
                  canCheckout={
                    isFormValid && !fulfillmentContext.updating && cartValid
                  }
                  confirmOrderError={confirmOrderError}
                  setEditingAccount={setEditingAccount}
                />
              </Form>
            )}
          </>
        )
      }}
    </Formik>
  )
}
