import { useQuery } from 'react-query'
import { useCustomerAuth } from '../auth/CustomerAuthContext'
import { domain, get, getQueryParams } from '../api/util'
import { useFulfillment } from '../components/FulfillmentProvider/FulfillmentProvider'
import { Selection } from '../types/orders'

export interface RecentOrders {
  orders: RecentOrder[]
}

export interface RecentOrder {
  orderGuid: string
  selections: Selection[]
  itemSubtotal: number
  unavailableItems: string[]
  createdDate: string
}

export const getRecentOrders = (
  fastLinkName: string | undefined
): Promise<RecentOrders> => {
  const path = `https://${domain}/catering/v1/public/orders/recent${getQueryParams(
    {
      fastLinkName: fastLinkName
    }
  )}`

  return get(path, { includeAuthHeader: true }).then((response) =>
    response.json()
  )
}

export const useRecentOrders = () => {
  const { customer } = useCustomerAuth()
  const { fastLinkName } = useFulfillment()

  return useQuery<RecentOrders>(
    ['recent-orders', customer?.id, fastLinkName],
    () => getRecentOrders(fastLinkName),
    {
      enabled: !!customer?.phone
    }
  )
}
