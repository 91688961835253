import React from 'react'
import styles from './RestaurantAndBanner.module.css'
import { RestaurantAddress } from '../../../RestaurantAddress/RestaurantAddress'
import cx from 'classnames'
import { Button } from '@local/do-secundo-button'
import { Address } from '../../../../types/config'

export interface RestaurantAndBannerProps {
  whiteLabelName: string
  address: Address
  bannerUrl: string | undefined
  onClickMoreInfo: () => void
}

export const RestaurantAndBanner = ({
  whiteLabelName,
  address,
  bannerUrl,
  onClickMoreInfo
}: RestaurantAndBannerProps) => {
  const { address1, address2, city, state, zip, phone } = address

  const textStyle = bannerUrl
    ? styles.whiteTextWithShadow
    : 'text-coo-primary-text'
  const linkStyle = bannerUrl ? styles.whiteTextWithShadow : 'text-link'

  const noBannerHeight = !bannerUrl && styles.noBannerHeight

  return (
    <div
      role='group'
      aria-label='restaurant information'
      data-testid='RestaurantDetails'
    >
      <div className={cx(styles.container, noBannerHeight)}>
        {bannerUrl && (
          <>
            <img
              data-testid='BannerImage'
              className={styles.bannerImage}
              src={bannerUrl}
              alt={whiteLabelName}
            />
            <div className={styles.gradient} />
          </>
        )}
        <div className='absolute w-full px-10 py-5 lg:w-5/6'>
          <div className={cx(styles.textContainer, noBannerHeight)}>
            <h2 className={cx(textStyle, styles.text)} itemProp={'name'}>
              {whiteLabelName}
            </h2>
            {phone && <meta itemProp='telephone' content={phone} />}
            <div className={'flex flex-wrap pt-4'}>
              <div
                itemProp={'address'}
                itemType={'https://schema.org/PostalAddress'}
              >
                <meta
                  itemProp='streetAddress'
                  content={`${address1}${address2 ? `, ${address2}` : ''}`}
                />
                <meta itemProp='addressLocality' content={city} />
                <meta itemProp='addressRegion' content={state} />
                <meta itemProp='postalCode' content={zip} />
                <RestaurantAddress
                  address1={address1}
                  address2={address2}
                  city={city}
                  state={state}
                  className={cx(textStyle, 'mr-4')}
                />
              </div>
              <Button
                data-testid='rx-info-button'
                className={cx('w-24 -ml-3', linkStyle)}
                onClick={onClickMoreInfo}
              >
                More info
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
