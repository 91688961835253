import * as React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import envFromHostname from '@toasttab/env-from-hostname'
import { BanquetApp } from '../client/components/App/BanquetApp'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { datadogRum } from '@datadog/browser-rum'

const env = envFromHostname(window.location.hostname)
const isProd = env === 'prod'
const version = process.env.PKG_VERSION

const sentryConfig = {
  publicKey: '5be03d53ecf14292a7227f11cbe6362e@o37442',
  projectId: '6741432',
  releaseVersion: version
}

datadogRum.init({
  applicationId: 'f560ab4c-a844-43fd-8577-c6feaefa967f',
  clientToken: 'pub060dac664356492dcb3856a3f719cc62',
  site: 'datadoghq.com',
  service: 'toastweb',
  env: env === 'preprod' ? 'preproduction' : env,
  version,
  trackInteractions: true,
  trackViewsManually: true,
  sampleRate: isProd ? 1 : 100,
  allowedTracingOrigins: isProd ? ['https://www.toasttab.com', 'https://toasttab.com'] : ['https://preprod.eng.toasttab.com', 'https://dev.eng.toastteam.com']
})

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'coo',
  // @ts-ignore
  rootComponent: BanquetApp,
  sentry: sentryConfig,
  singleSpaCssLifecycles
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount

