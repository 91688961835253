import { CloseButton } from '@local/do-secundo-closebutton'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Progress } from '@local/do-secundo-progress'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import * as React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { formatPhoneNumber } from '../../utils/phone-number'
import { toRelativeDateString } from '../../utils/time-utils'
import { CartFooter } from '../Cart/CartFooter/CartFooter'
import { CartTable } from '../Cart/CartTable/CartTable'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { RestaurantAddress } from '../RestaurantAddress/RestaurantAddress'
import { ScrollToTop } from '../ScrollToTop/ScrollToTop'
import { BackToMenuButton } from './BackToMenuButton/BackToMenuButton'
import { useQuery } from 'react-query'
import { getCompletedOrder } from '../../api/orders'
import { formatHours } from '../../utils/restaurant-schedule-helpers'
import styles from './ConfirmPage.module.css'
import { LoyaltyConfirmation } from './LoyaltyConfirmation'
import { ReplateBanner } from './ReplateBanner'
import ConfirmationTag from '../../../packages/ad-tracking/ConfirmationTag'
import { CompletedOrder, DeliveryInfo } from '../../types/orders'
import { Address } from '../../types/config'

const getOrderReadyText = (
  deliveryInfo: DeliveryInfo | undefined,
  tdsDeliveryDate: string | undefined
) => {
  let mode = deliveryInfo ? 'delivered' : 'ready'
  if (tdsDeliveryDate) mode += ' by Toast Delivery Services'

  return `Your order will be ${mode}`
}

export const ConfirmPage = () => {
  const { orderGuid } = useParams<{ orderGuid: string }>()
  const {
    getRestaurantPath,
    restaurantGuid,
    restaurantInfo,
    applicableConfigs: { brandingConfig }
  } = useRestaurant()

  const {
    isLoading,
    isError,
    data: completedOrder
  } = useQuery(['completed-order', orderGuid], () =>
    getCompletedOrder(orderGuid!!)
  )

  if (isLoading) return <Progress />

  if (!completedOrder || isError)
    return <Navigate to={getRestaurantPath()} replace />

  const check = completedOrder.checks[0]

  if (!check || !restaurantInfo) {
    return null
  }

  const { estimatedFulfillmentDate, deliveryServiceInfo } = completedOrder
  const restaurantLocation = restaurantInfo.address
  const deliveryInfo = completedOrder.deliveryInfo

  const firstName = check?.customer?.firstName
  const cart = {
    order: completedOrder
  }

  const removeCatering = brandingConfig?.removeCateringName ?? false
  const tdsDeliveryDate = deliveryServiceInfo?.originalQuotedDeliveryDate

  return (
    <div className={styles.confirmPage}>
      <ScrollToTop />
      <ConfirmationTag completedOrder={completedOrder} />
      <div className='pt-12 sm:pt-6 px-10 text-center'>
        <h2
          data-testid='order-sent-header'
          className='type-headline-3 font-bold mb-4'
        >
          {`${removeCatering ? 'Order' : 'Catering order'} sent`}
        </h2>
        <p
          data-testid='confirmation-text'
          className='type-headline-5 text-default space-y-2 mb-4'
        >
          {firstName ? `Thanks ${firstName}!` : 'Thanks!'}
          <br />
          {getOrderReadyText(deliveryInfo, tdsDeliveryDate)}
        </p>
        <p
          data-testid='order-ready-time'
          className='mt-2 mb-6 type-headline-3 font-bold text-primary-75'
        >
          {toRelativeDateString(
            estimatedFulfillmentDate!!,
            restaurantInfo.timeZoneId
          )}
          <br />
          {deliveryInfo ? 'Estimated arrival ' : 'at '}
          <span className='whitespace-nowrap type-headline-3 font-bold'>
            {formatHours(
              tdsDeliveryDate ?? estimatedFulfillmentDate,
              restaurantInfo.timeZoneId
            )}
          </span>
        </p>
        <AddressInfoComponent
          deliveryInfo={deliveryInfo}
          completedOrder={completedOrder}
        />
        {deliveryInfo?.notes && (
          <p data-testid='delivery-note-wrapper' className={styles.text}>
            <strong>Your instructions: </strong>
            <span data-testid='delivery-note'>{deliveryInfo.notes}</span>
          </p>
        )}
      </div>
      <div className={styles.emailConfirmationWrapper}>
        <p
          data-testid='email-confirmation-send-message'
          className={styles.text}
        >
          An email receipt has been sent.
        </p>
        <h2 data-testid='restaurant-name' className='type-headline-5 font-bold'>
          {restaurantInfo?.name}
        </h2>
        {restaurantLocation && (
          <div className={styles.text}>
            <RestaurantAddress
              address1={restaurantLocation.address1}
              address2={restaurantLocation.address2 || undefined}
              city={restaurantLocation.city}
              state={restaurantLocation.state}
            />
          </div>
        )}
        {restaurantLocation.phone && (
          <p className={styles.text}>
            Issue with your order? Call {restaurantInfo.name}{' '}
            <a className='text-link' href={`tel:${restaurantLocation.phone}`}>
              {formatPhoneNumber(restaurantLocation.phone)}
            </a>
          </p>
        )}
      </div>
      <LoyaltyConfirmation check={check} />
      <Fieldset
        label={`Your ${removeCatering ? 'order' : 'catering order'}`}
        collapsable
      >
        <CartTable cart={cart} editable={false} />
      </Fieldset>
      <div className={styles.cartFooter}>
        <CartFooter
          // @ts-ignore
          cart={cart}
          showTotal
          showTip={true}
        />
      </div>
      {(deliveryInfo ?? restaurantLocation) && (
        <ReplateBanner
          restaurantGuid={restaurantGuid}
          orderGuid={orderGuid!!}
          location={
            deliveryInfo ?? {
              city: restaurantLocation!!.city,
              state: restaurantLocation!!.state
            }
          }
        />
      )}
      <br />
      <BackToMenuButton getRestaurantPath={getRestaurantPath} />
      <div className={styles.closeButton}>
        <CloseButton
          data-testid='close-button-order-sent-page'
          to={getRestaurantPath()}
        />
      </div>
      <div className='flex justify-center pb-12'>
        <PoweredByToast className='w-36' />
      </div>
    </div>
  )
}

const AddressInfoComponent = ({
  deliveryInfo,
  completedOrder
}: {
  deliveryInfo: DeliveryInfo | undefined
  completedOrder: CompletedOrder
}) => {
  if (deliveryInfo) {
    return (
      <p
        data-testid='delivery-info'
        className='type-headline-5 mb-4 block text-default'
      >
        to{' '}
        {completedOrder.customLocationName && (
          <>
            {completedOrder.customLocationName}
            <br />
          </>
        )}
        <AddressLabel address={deliveryInfo} />
      </p>
    )
  }

  if (completedOrder.customLocationName) {
    return (
      <p
        data-testid='takeout-custom-location'
        className='type-headline-5 mb-4 block text-default'
      >
        from {completedOrder.customLocationName}
        {completedOrder.customLocationAddress && (
          <>
            <br />
            <AddressLabel address={completedOrder.customLocationAddress} />
          </>
        )}
      </p>
    )
  }

  return null
}

const AddressLabel = ({ address }: { address: Address | DeliveryInfo }) => {
  return (
    <>
      {address.address1}
      {address.address2 ? ` ${address.address2}` : ''}
      , <br />
      {address.city}, {address.state} {address.zipCode}
    </>
  )
}
