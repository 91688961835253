import React, { useCallback, useState } from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ToastAccountIcon } from '@toasttab/buffet-pui-icons'
import { FF, useFlag } from '@local/do-secundo-feature-flag/src'
import { ListItem, MenuDropdown } from '@toasttab/buffet-pui-dropdowns'
import LogoutIcon from './LogoutIcon'
import { useCustomerAuth } from '../auth/CustomerAuthContext'
import { alertSuccess } from '../auth/alertUtils'
import { PwlessAuthModal } from '../auth/PwlessAuthModal'
import { AuthenticationSource } from '../auth/auth'
import { LoggingOutModal } from './LoggingOutModal'
import { AccountModal } from './AccountModal'
import { useCart } from '@local/do-secundo-cart-provider/src'
import { deleteGuest } from '../api/cart'
import { useTracker } from '../analytics/tracker'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'

export const AccountButton = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = React.useState(false)
  const [isLoggingOut, setIsLoggingOut] = React.useState(false)
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false)

  const tracker = useTracker()
  const { restaurantGuid } = useRestaurant()

  const { cartGuid } = useCart()

  const logoutOnOpen = () => setIsLoggingOut(true)
  const logoutOnClose = () => setIsLoggingOut(false)

  const pwLessAuthEnabled = useFlag(FF.NVS_COO_PWLESS_AUTH)

  const { customer, pwlessLogout } = useCustomerAuth()

  const [logoutError, setLogoutError] = useState(false)
  const logout = useCallback(async () => {
    logoutOnOpen()
    const success = await pwlessLogout()
    if (success) {
      if (cartGuid) {
        deleteGuest(cartGuid)
      }
      logoutOnClose()
      alertSuccess('You are now logged out')
      tracker.trackAccountLogout(restaurantGuid)
    } else {
      setLogoutError(true)
    }
  }, [
    logoutOnOpen,
    logoutOnClose,
    pwlessLogout,
    cartGuid,
    deleteGuest,
    tracker,
    restaurantGuid
  ])

  if (!customer) {
    return (
      <>
        {pwLessAuthEnabled && (
          <IconButton
            testId={'account-button'}
            icon={<ToastAccountIcon />}
            onClick={() => setIsAuthModalOpen(true)}
            textClassName='text-default'
          />
        )}
        <PwlessAuthModal
          source={AuthenticationSource.UserNav}
          isOpen={isAuthModalOpen}
          onClose={() => setIsAuthModalOpen(false)}
        />
      </>
    )
  }

  return (
    <div className={'flex items-center'}>
      {customer && (
        <div className={'text-default overflow-hidden text-ellipsis'}>
          {customer.firstName} {customer.lastName}
        </div>
      )}
      <div className={'flex-none'}>
        <MenuDropdown
          testId={'account-dropdown'}
          renderToggle={(props) => (
            <IconButton
              {...props}
              icon={<ToastAccountIcon accessibility='decorative' />}
              textClassName='text-default'
            />
          )}
          preventCloseOnClick
        >
          <ListItem
            icon={<ToastAccountIcon accessibility='decorative' />}
            label={'My account'}
            onClick={() => setIsAccountModalOpen(true)}
          />
          <ListItem
            icon={<LogoutIcon color={'currentColor'} />}
            label={'Log out'}
            onClick={logout}
          />
        </MenuDropdown>
        <LoggingOutModal isOpen={isLoggingOut} error={logoutError} />
        <AccountModal
          isOpen={isAccountModalOpen}
          onClose={() => setIsAccountModalOpen(false)}
        />
      </div>
    </div>
  )
}
