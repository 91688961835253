import React, { useState, useMemo } from 'react'
import cx from 'classnames'
import { Button } from '@local/do-secundo-button'
import { EditItem } from '../../Modifiers/EditItem'
import { CartTableModifiersList } from './CartTableModifiersList/CartTableModifiersList'
import { ItemPrice } from '../../ItemPrice/ItemPrice'
import { stopPropagationIfExists } from '../../../utils/event-helpers'
import styles from './CartTable.module.css'
import { Cart } from '../../../types/cart'
import { CartTableRemoveItemButton } from './CartTableRemoveItemButton'
import { ErrorComponent } from '@local/do-secundo-error'
import { Selection } from '../../../types/orders'

interface Props {
  cart: Pick<Cart, 'order'>
  editable?: boolean
}

export const CartTable: React.FC<Props> = ({ cart, editable = false }) => {
  return (
    <SelectionsTable
      selections={cart.order.checks[0]?.selections || []}
      editable={editable}
    />
  )
}

export const SelectionsTable = ({
  selections,
  editable
}: {
  selections: Selection[]
  editable?: boolean
}) => {
  const [deleting, setDeleting] = useState(false)
  const [currentEditGuid, setEditGuid] = useState<string>()
  const [error, setError] = useState(undefined)

  const onCloseHandler = useMemo(
    () => stopPropagationIfExists(() => setEditGuid(undefined)),
    [setEditGuid]
  )

  return (
    <div data-testid={'cart-table'}>
      {error && <ErrorComponent error={error} isActionable={false} />}
      <table
        className={cx(styles.table, { [styles.readonly || '']: !editable })}
      >
        <tbody>
          {selections.map((selection, index) => {
            const {
              displayName,
              price,
              preDiscountPrice,
              externalId,
              modifiers,
              itemGroup: { guid: itemGroupGuid },
              item: { guid: itemGuid }
            } = selection
            const key = externalId || index

            const handleSetEditGuid = () => {
              if (!editable) return
              setEditGuid(selection.externalId)
            }

            return (
              <tr
                data-testid='cart-item-body'
                key={key}
                role='group'
                aria-label={`item ${index + 1} of ${selections.length}`}
              >
                <td
                  data-testid='cart-item-quantity'
                  valign='top'
                  className={cx(styles.title, styles.number, 'type-default')}
                  aria-label='quantity'
                >
                  {selection.quantity}
                </td>
                <td
                  onClick={handleSetEditGuid}
                  data-testid='cart-item-info'
                  className={styles.info}
                >
                  <span
                    data-testid='cart-item-name'
                    className={cx(styles.title, 'type-default')}
                  >
                    {displayName}
                  </span>
                  {modifiers.length > 0 && (
                    <CartTableModifiersList
                      editable={editable}
                      modifiers={modifiers}
                    />
                  )}
                  {editable && selection.externalId && (
                    <>
                      <Button
                        disabled={deleting}
                        data-testid='cart-item-edit'
                        onClick={handleSetEditGuid}
                      >
                        Edit
                      </Button>
                      <CartTableRemoveItemButton
                        selectionGuid={selection.externalId}
                        setDeleting={setDeleting}
                        setError={setError}
                        disabled={deleting}
                      />
                    </>
                  )}
                  {selection.externalId &&
                    selection.externalId === currentEditGuid && (
                      <EditItem
                        selectionGuid={selection.externalId}
                        itemGuid={itemGuid}
                        itemGroupGuid={itemGroupGuid}
                        onClose={onCloseHandler}
                      />
                    )}
                </td>
                <ItemPrice price={price} preDiscountPrice={preDiscountPrice} />
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
