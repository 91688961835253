import { prodConfig } from './config-prod'
import { devConfig } from './config-devpreprod'

export type Config = {
  amplitudeApiKey: string
}

let config: Config
if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') {
  config = prodConfig
} else {
  config = devConfig
}

export const amplitudeApiKey = config.amplitudeApiKey
