import { useCart } from '@local/do-secundo-cart-provider'
import {
  addItemToCart,
  createCart,
  deleteItemInCart,
  editItemInCart
} from '../../api/cart'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { ItemSelectionRequest, SelectionRequest } from '../../types/orders'
import { useCallback } from 'react'
import { ModifierGroup } from '@local/do-secundo-model'
import { useTracker } from '../../analytics/tracker'
import { useCustomerAuth } from '../../auth/CustomerAuthContext'

export interface SelectionViewModel {
  itemGroupGuid: string
  itemGuid: string
  modifierGroups: any[]
  quantity: number
  specialInstructions: string
}

export const useAddItemToCart = () => {
  const { cartGuid, updateCartCache } = useCart()
  const fulfillment = useFulfillment()

  const tracker = useTracker()
  const { isLoggedIn } = useCustomerAuth()

  return useCallback(
    async (selection: SelectionViewModel) => {
      const cart = await (!cartGuid
        ? createCart({
            fulfillment: {
              ...fulfillment,
              fulfillmentDate:
                fulfillment.fulfillmentDateTime || new Date().toISOString()
            },
            selections: [
              convertToApiModel(selection, fulfillment.fastLinkName)
            ],
            metadata: { fastLinkName: fulfillment.fastLinkName }
          })
        : addItemToCart(
            cartGuid,
            convertToApiModel(selection, fulfillment.fastLinkName)
          ))

      updateCartCache(cart)

      tracker.trackAddItem(cart.restaurantGuid, isLoggedIn)
      return cart
    },
    [tracker, isLoggedIn, fulfillment]
  )
}

export const useEditItemInCart = () => {
  const { cartGuid, updateCartCache } = useCart()

  const { fastLinkName } = useFulfillment()

  return async (selectionGuid: string, selection: SelectionViewModel) => {
    if (!cartGuid) {
      throw new Error('Unable to edit item, cart GUID does not exist')
    }

    const cart = await editItemInCart(cartGuid, selectionGuid, {
      externalId: selectionGuid,
      ...convertToApiModel(selection, fastLinkName)
    })

    updateCartCache(cart)
    return cart
  }
}

const useDeleteItemFromCart = () => {
  const { cartGuid, updateCartCache } = useCart()
  const { fastLinkName } = useFulfillment()

  return async (selectionGuid: string) => {
    if (!cartGuid) {
      return
    }

    const cart = await deleteItemInCart(cartGuid, selectionGuid, fastLinkName)

    updateCartCache(cart)
    return cart
  }
}

export const useHandleDelete = ({
  selectionGuid,
  setError
}: {
  selectionGuid?: string
  setError: (error: any) => void
}) => {
  const deleteItemFromCart = useDeleteItemFromCart()
  return useCallback(async () => {
    if (selectionGuid) {
      await deleteItemFromCart(selectionGuid).catch(async (e: Response) =>
        setError(await e.json())
      )
    }
  }, [deleteItemFromCart, selectionGuid, setError])
}

const convertToApiModel = (
  selection: SelectionViewModel,
  fastLinkName: string | undefined
): ItemSelectionRequest => {
  const modifiers = convertModifiersToApiModel(selection.modifierGroups)

  return {
    item: { guid: selection.itemGuid },
    itemGroup: { guid: selection.itemGroupGuid },
    selectionType: 'NONE',
    quantity: selection.quantity,
    modifiers: selection.specialInstructions
      ? [
          ...modifiers,
          {
            selectionType: 'SPECIAL_REQUEST',
            displayName: selection.specialInstructions
          }
        ]
      : modifiers,
    fastLinkName: fastLinkName
  }
}

const convertModifiersToApiModel = (
  modifierGroups: ModifierGroup[]
): SelectionRequest[] => {
  return modifierGroups.flatMap((group) => {
    return group.modifiers.map((modifier) => ({
      optionGroup: { guid: group.guid },
      item: { guid: modifier.itemGuid },
      selectionType: 'NONE',
      modifiers: convertModifiersToApiModel(modifier.modifierGroups)
    }))
  })
}
