import { PaymentInput, placeOrder, PlaceOrderRequest } from '../../api/cart'
import {
  AuthorizePaymentInput,
  AuthorizePaymentResponse,
  PaymentState
} from '../../apollo/generated/TakeoutWebGraphQLOperations'
import { Cart } from '../../types/cart'
import { PaymentType } from '../../types/orders'
import { ArgsForSubmit } from '../CheckoutForm/utils'
import { getCheckTotal } from '../../utils/cart-helpers'
import { EventErrorContent, SpiSdk } from '../CreditCard/types'
import { useReCaptcha } from '../../hooks/use-recaptcha'
import { useSentry } from 'banquet-runtime-modules'
import { Tracker, useTracker } from '../../analytics/tracker'
import { useCustomerAuth } from '../../auth/CustomerAuthContext'
import { useAuthorizeCard } from './useAuthorizeCard'
import { useNavigate } from 'react-router-dom'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useSpiSdk } from '../CreditCard/useSpi'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useGetCart } from '../CartQuery/CartQuery'
import { useState } from 'react'
import { FF, useFlag } from '@local/do-secundo-feature-flag'

export interface ConfirmOrderError {
  error: Error | string
  errorType: 'PAYMENT_AUTH' | 'ORDER_PLACED'
}

interface HandlePlaceOrderArgs extends ArgsForSubmit {
  paymentType: PaymentType
}

interface HandlePaymentArgs extends HandlePlaceOrderArgs {
  captureException: (error: Error) => void
  setErrorMessage: (error: ConfirmOrderError) => void
  spiSdk: SpiSdk | undefined
  cart: Cart
  tracker: Tracker
}

interface LegacyHandlePaymentArgs extends HandlePaymentArgs {
  generateReCaptchaToken: (action: string) => Promise<string | undefined>
  doAuthorization: (
    input: Omit<AuthorizePaymentInput, 'restaurantGuid'>
  ) => Promise<AuthorizePaymentResponse | undefined>
  fastLinkName: string | undefined
}

export const useHandlePlaceOrder = () => {
  const { captureException } = useSentry()

  const { doAuthorization } = useAuthorizeCard()
  const { generateReCaptchaToken } = useReCaptcha()

  const spiSdk = useSpiSdk()
  const spiEnabled = useFlag(FF.NVS_COO_SPI)

  const { isLoggedIn } = useCustomerAuth()
  const { fastLinkName } = useFulfillment()
  const { cart, deleteCartGuid } = useGetCart()

  const tracker = useTracker()

  const trackSuccess = () =>
    cart ? tracker.trackPlaceOrder(cart.restaurantGuid, isLoggedIn) : undefined
  const trackError = () =>
    cart
      ? tracker.trackPlaceOrderError(cart.restaurantGuid, isLoggedIn)
      : undefined

  const { getRestaurantPath } = useRestaurant()
  const navigate = useNavigate()

  const navigateOnSuccess = (guid: string) =>
    navigate(getRestaurantPath(`confirm/${guid}`), {
      replace: true
    })

  const [errorMessage, setErrorMessage] = useState<ConfirmOrderError>()

  const handlePlaceOrder = async (args: HandlePlaceOrderArgs) => {
    if (!cart) return
    const {
      ccInput,
      paymentType,
      formValues,
      loyaltyEnroll,
      companyName,
      shouldLookupLoyalty
    } = args

    try {
      let paymentInput: PaymentInput = {
        paymentType
      }

      const paymentArgs = {
        ...args,
        captureException,
        setErrorMessage,
        spiSdk,
        cart,
        tracker
      }

      setErrorMessage(undefined)

      if (paymentType === 'CREDIT') {
        const paymentGuid = spiEnabled
          ? await handleSpiPaymentRequest(paymentArgs)
          : await handleLegacyPaymentRequest({
              ...paymentArgs,
              generateReCaptchaToken,
              doAuthorization,
              fastLinkName
            })

        if (!paymentGuid) {
          trackError()
          return
        }

        paymentInput = {
          paymentType: 'CREDIT',
          tipAmount: ccInput.tipAmount,
          paymentGuid: paymentGuid
        }
      }

      const request: PlaceOrderRequest = {
        formValues,
        paymentInput,
        customer: ccInput.customer,
        deliveryInfo: ccInput.newAddress?.deliveryInfo,
        loyaltyEnroll,
        companyName,
        shouldLookupLoyalty,
        fastLinkName
      }

      const placedCart = await placeOrder(cart.guid, request)
      const { guid } = placedCart.order
      trackSuccess()
      deleteCartGuid(fastLinkName)
      navigateOnSuccess(guid)
    } catch (e: any) {
      if (e instanceof Response) {
        const error = await e.json()
        if (error.messageKey) {
          setErrorMessage({
            error: error,
            errorType: 'ORDER_PLACED'
          })
        } else if (error.message) {
          setErrorMessage({
            error: `Unable to place order. ${error.message}`,
            errorType: 'ORDER_PLACED'
          })
        } else {
          setErrorMessage({
            error: 'Unable to place order, please try again.',
            errorType: 'ORDER_PLACED'
          })
        }
      } else {
        captureException(e)
        setErrorMessage({
          error: 'Unable to place order, please try again.',
          errorType: 'ORDER_PLACED'
        })
      }

      trackError()
    }
  }

  return { handlePlaceOrder, confirmOrderError: errorMessage }
}

const isGooglePayError = (error?: EventErrorContent) =>
  Boolean(error?.cause?.message?.includes('Google Pay'))

// Some "errors" from the confirmPayment function are expected errors,
// such as when the user backed out of the Apple Pay or Google Pay flow.
// The "processing your card" error occurs when the payment is denied
// or rejected by fraud.
const isExpectedPaymentError = (error: string | undefined) => {
  if (!error) return false
  const formattedError = error.toLowerCase()
  return (
    formattedError.includes('session cancelled') ||
    formattedError.includes('user closed') ||
    formattedError.includes('error occurred processing your card payment') ||
    formattedError.includes('google pay transaction') ||
    formattedError.includes('apple pay transaction')
  )
}

const handleSpiPaymentRequest = async ({
  spiSdk,
  cart,
  ccInput,
  setErrorMessage,
  captureException,
  tracker
}: HandlePaymentArgs) => {
  let paymentGuid: string | undefined = undefined

  const onCreatePaymentSuccess = async () => {
    tracker.trackSpiCreatePaymentSuccess(cart.restaurantGuid)
    await spiSdk?.confirmPayment(
      async (event) => {
        paymentGuid = event.content.payment?.externalReferenceId
        tracker.trackSpiConfirmPaymentSuccess(cart.restaurantGuid)
      },
      async (error) => {
        if (isGooglePayError(error)) {
          spiSdk?.clearPaymentSelection()
        }

        const errorMessage = await getErrorFromSpiRequest(error)
        if (!isExpectedPaymentError(errorMessage)) {
          captureException(
            new Error(
              `SPI - Failed to confirm payment for cart ${cart.guid}: ${errorMessage}`
            )
          )
          tracker.trackSpiConfirmPaymentFailure(cart.restaurantGuid)
        }

        setErrorMessage({
          error: 'Unable to process your payment, please try again',
          errorType: 'PAYMENT_AUTH'
        })
      }
    )
  }

  await spiSdk?.createPaymentMethod(
    onCreatePaymentSuccess,
    async (error) => {
      if (isGooglePayError(error)) {
        spiSdk?.clearPaymentSelection()
      }
      const errorMessage = await getErrorFromSpiRequest(error)
      if (!isExpectedPaymentError(errorMessage)) {
        captureException(
          new Error(`SPI - Failed to create payment: ${errorMessage}`)
        )
        tracker.trackSpiCreatePaymentFailure(cart.restaurantGuid)
      }
      setErrorMessage({
        error: 'Unable to process your payment, please try again',
        errorType: 'PAYMENT_AUTH'
      })
    },
    [
      { label: 'Subtotal', amount: getCheckTotal(cart) },
      { label: 'Tip', amount: ccInput.tipAmount }
    ]
  )

  return paymentGuid
}

const getErrorFromSpiRequest = async (
  error: EventErrorContent | Response | any
): Promise<string> => {
  if (error.cause?.message) {
    return error.cause.message
  }

  if (error instanceof Response) {
    try {
      const errorBody = await error.json()
      return (
        errorBody.developerMessage ??
        errorBody.message ??
        JSON.stringify(errorBody)
      )
    } catch (e) {}
  }

  return JSON.stringify(error)
}

const handleLegacyPaymentRequest = async ({
  cart,
  ccInput,
  generateReCaptchaToken,
  doAuthorization,
  setErrorMessage,
  fastLinkName,
  captureException
}: LegacyHandlePaymentArgs) => {
  let token: string | undefined = undefined
  try {
    token = await generateReCaptchaToken('authorize_payment')
  } catch (e: any) {
    captureException(e)
    setErrorMessage({
      error: 'Failed to load reCAPTCHA. Please reload the page and try again.',
      errorType: 'PAYMENT_AUTH'
    })
    return undefined
  }

  const payment = await doAuthorization({
    newCardInput: ccInput.newCardInput,
    tipAmount: ccInput.tipAmount,
    amount: cart.order.checks[0].totalAmount,
    email: ccInput.customer.email,
    reCaptchaToken: token,
    cartGuid: cart.guid,
    fastLinkName: fastLinkName
  })

  if (
    payment?.paymentState === PaymentState.Denied ||
    payment?.paymentState === PaymentState.FraudReject ||
    payment?.denialReason?.toLowerCase().includes('trans denied')
  ) {
    setErrorMessage({
      error: 'Your payment transaction was denied.',
      errorType: 'PAYMENT_AUTH'
    })
    return undefined
  } else if (payment?.denialReason) {
    setErrorMessage({
      error: payment.denialReason,
      errorType: 'PAYMENT_AUTH'
    })
    return undefined
  } else if (
    !payment ||
    !payment.paymentGuid ||
    payment.denialReason?.toLowerCase().includes('invalid request')
  ) {
    setErrorMessage({
      error:
        'Unexpected error occurred processing your payment, please try again.',
      errorType: 'PAYMENT_AUTH'
    })
    return undefined
  }

  return payment?.paymentGuid
}
