import { useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'

export const useDebounce = (
  funcToDebounce: (...args: any) => any,
  ms: number
) => {
  return useMemo(() => debounce(funcToDebounce, ms, {}), [ms])
}

// Wrapper for useEffect that won't run on first render, only runs
// when the dependencies change after they've first mounted.
export const useEffectOnChange = (callback: any, deps: any[]) => {
  const ref = useRef(0)
  useEffect(() => {
    if (ref.current !== 0) {
      callback()
    }
    ref.current++
  }, deps)
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function usePreviousHandleStateChange<T>(value: T): T | undefined {
  const [current, setCurrent] = useState<T | undefined>()
  const previous = usePrevious(current)

  useEffect(() => {
    setCurrent(value)
  }, [value])

  return previous
}
