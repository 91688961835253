import React, { useState } from 'react'

import { useCustomerAuth } from '../auth/CustomerAuthContext'
import { CreditCardRow } from './CreditCardRow'

import styles from './account.module.css'
import { Label } from '@toasttab/buffet-pui-input-base'

export const ProfileWallet = (props: { nowDate?: Date }) => {
  return (
    <div
      className={styles.profileWallet}
      data-testid='ProfileWallet'
      role='form'
      tabIndex={0}
    >
      <Label>My Wallet</Label>
      {/*TODO - support toast cash*/}
      {/*<ToastCashBalance nowDate={props.nowDate} />*/}
      <SavedCreditCards />
    </div>
  )
}

const SavedCreditCards = () => {
  const { customer } = useCustomerAuth()
  const [errorMsg, setErrorMsg] = useState('')

  return (
    <div role='list' aria-label='Payment Methods'>
      <div>Payment Methods**</div>
      {customer?.creditCards?.length ? (
        <div className={'my-2'}>
          {customer?.creditCards.map((card) => (
            <CreditCardRow key={card.guid} {...card} onError={setErrorMsg} />
          ))}
        </div>
      ) : (
        <div className={styles.empty}>
          Check back here after placing an order for your saved payment methods!
        </div>
      )}
      {errorMsg && <div className='text-error'>{errorMsg}</div>}
      <div className={'text-xs mb-2'}>
        ** Card details are only saved for restaurants located in the U.S.
      </div>
    </div>
  )
}
