import { useCart } from '@local/do-secundo-cart-provider/src'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { reorder, reorderAddToCart } from '../../api/cart'
import { Cart, FulfillmentInfoRequest } from '../../types/cart'

export const useReorder = () => {
  const { updateCartCache } = useCart()
  const {
    fulfillmentDateTime,
    diningOptionBehavior,
    deliveryInfo,
    fastLinkName,
    customLocationName,
    standardFulfillment
  } = useFulfillment()

  return async (orderGuid: string, cartGuid: string | undefined) => {
    let cart: Cart
    if (cartGuid) {
      cart = await reorderAddToCart(cartGuid, { orderGuid })
    } else {
      // if they have picked a time already, use it. Otherwise, BE reorder logic will pick a time for them
      let fulfillmentRequest: FulfillmentInfoRequest | undefined = undefined
      if (fulfillmentDateTime) {
        fulfillmentRequest = {
          diningOptionBehavior,
          fulfillmentDate: fulfillmentDateTime,
          deliveryInfo: deliveryInfo,
          customLocationName: customLocationName,
          standardFulfillment: standardFulfillment,
          fastLinkName: fastLinkName
        }
      }

      cart = await reorder({
        fulfillment: fulfillmentRequest,
        orderGuid: orderGuid
      })
    }

    updateCartCache(cart)
    return cart
  }
}
