import { useEffect } from 'react'
import { useOOGlobals } from '@local/do-secundo-oo-globals-provider'
import { useSentry } from 'banquet-runtime-modules'

const SESSION_REFRESH_KEY = 'catering-oo-session-refresh'

export const useSessionRefresh = () => {
  const { session } = useOOGlobals()
  const { captureException } = useSentry()

  useEffect(() => {
    let timerId: any

    // Storing whether a refresh was triggered is a safety net in case
    // something is up with the session's expiresAt value, this way we
    // won't trigger an infinite loop refreshing the page.
    const sessionRefreshTriggered = localStorage.getItem(SESSION_REFRESH_KEY)

    const refreshPage = () => {
      localStorage.setItem(SESSION_REFRESH_KEY, 'true')
      window.location.reload()
    }

    const currentTime = Date.now() / 1000

    if (session && session.expiresAt < currentTime) {
      if (sessionRefreshTriggered) {
        captureException(
          new Error(
            'Session expired triggered second page refresh. session expiresAt: ' +
              session.expiresAt +
              ' currentTime: ' +
              currentTime +
              ' session id: ' +
              session.id
          )
        )
      } else {
        refreshPage()
      }
    } else {
      localStorage.removeItem(SESSION_REFRESH_KEY)
      timerId = setTimeout(refreshPage, 1000 * 60 * 60) // 1 hour
    }

    return () => clearTimeout(timerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])
}
