import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { getRxDateFromISO } from '../../../utils/time-utils'
import { RecentOrder } from '../../../hooks/use-recent-orders'

export const getFormatedCreatedDate = (
  order: RecentOrder,
  timeZoneId?: string
) =>
  format(getRxDateFromISO(order.createdDate, timeZoneId)!!, Formats.date.medium)

export const getFormatedItemCount = (order: RecentOrder) => {
  const totalQuantity = order.selections.reduce(
    (sum, selection) => sum + selection.quantity,
    0
  )

  return `${totalQuantity} item${totalQuantity === 1 ? '' : 's'}`
}

export const getDescription = (order: RecentOrder) => {
  const groupedSelections = order.selections.reduce((acc, selection) => {
    if (acc[selection.displayName]) {
      acc[selection.displayName] += selection.quantity
    } else {
      acc[selection.displayName] = selection.quantity
    }
    return acc
  }, {} as Record<string, number>)

  return Object.entries(groupedSelections)
    .map(([name, qty]) => `${name} x${qty}`)
    .join(', ')
}
