import React from 'react'
import { useApprovalRulesValidator } from './use-approval-rules-validator'
import { OrderLessThanRule } from './OrderLessThanRule'
import { Cart } from '../../../types/cart'
import { Notification, NotificationProps } from '@local/do-secundo-notification'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { OrderGreaterThanRule } from './OrderGreaterThanRule'
import { useRedirectToFulfillment } from '../../../hooks/useRedirectToFullfilment'

interface Props {
  cart: Cart
}

export const useCartValidator = (cart?: Cart) => {
  const { ruleMap, valid: rulesValid } = useApprovalRulesValidator({ cart })

  if (!cart) {
    return { cartValid: false }
  }

  const { warnings } = cart

  const cartValid =
    rulesValid &&
    warnings &&
    warnings.length === 0 &&
    cart.order.checks[0].selections.length > 0

  return { ruleMap, warnings, cartValid }
}

export const getWarnings = (
  restaurantName: string | undefined = 'The restaurant',
  behavior: string,
  redirectToFulfillment: () => void
) => {
  const WARNINGS: Record<
    string,
    Pick<NotificationProps, 'isActionable' | 'onAction' | 'actionContent'> & {
      message: (msg: string) => string
    }
  > = {
    'order-time': {
      message: () =>
        'Your selected order time is no longer available, please select a new time',
      actionContent: 'Update time',
      onAction: redirectToFulfillment,
      isActionable: true
    },
    'outside-delivery-area': {
      message: () =>
        "Your delivery address is not within the restaurant's delivery area"
    },
    'items-not-available': {
      message: (msg: string) =>
        `Some items in your cart are not available at this time. Please remove the following to proceed: ${msg}`
    },
    ITEM_QUANTITY: {
      message: (msg: string) => msg
    },
    'capacity-exceeded': {
      message: () =>
        `${restaurantName} is not accepting any more ${behavior} orders for this time, please select another time.`,
      actionContent: 'Update time',
      onAction: redirectToFulfillment,
      isActionable: true
    },
    'capacity-exceeded-dollars': {
      message: () =>
        `${restaurantName} cannot fulfill this ${behavior} order for this time, please select another time.`,
      actionContent: 'Update time',
      onAction: redirectToFulfillment,
      isActionable: true
    },
    'capacity-exceeded-total': {
      message: () =>
        `${restaurantName} is not accepting any more orders for this time, please select another time.`,
      actionContent: 'Update time',
      onAction: redirectToFulfillment,
      isActionable: true
    },
    'capacity-exceeded-dollars-total': {
      message: () =>
        `${restaurantName} cannot fulfill this order for this time, please select another time.`,
      actionContent: 'Update time',
      onAction: redirectToFulfillment,
      isActionable: true
    },
    'invalid-location': {
      message: () =>
        'Your selected location is no longer available, please select a new location.',
      actionContent: 'Update location',
      onAction: redirectToFulfillment,
      isActionable: true
    }
  }

  return WARNINGS
}

export const CartValidation: React.FC<Props> = ({ cart }) => {
  const { ruleMap, warnings, cartValid } = useCartValidator(cart)
  const { restaurantInfo } = useRestaurant()

  const behavior =
    cart.diningOptionBehavior === 'DELIVERY' ? 'delivery' : 'pickup'

  const { redirectToFulfillment } = useRedirectToFulfillment()

  const WARNINGS = getWarnings(
    restaurantInfo?.name,
    behavior,
    redirectToFulfillment
  )

  if (cartValid) {
    return null
  }

  const orderLtRule = ruleMap ? ruleMap['ORDER_LT'] : undefined
  const orderGtRule = ruleMap ? ruleMap['ORDER_GT'] : undefined

  return (
    <div className='px-5 max-h-44 md:max-h-64 overflow-y-auto pb-1'>
      {warnings?.map((warning, index) => {
        return (
          <div key={warning.messageKey}>
            <Warning
              index={index}
              messageKey={warning.messageKey}
              message={warning.message}
              WARNINGS={WARNINGS}
            />
          </div>
        )
      })}
      {orderLtRule && <OrderLessThanRule rule={orderLtRule} />}
      {orderGtRule && <OrderGreaterThanRule rule={orderGtRule} />}
    </div>
  )
}

type WarningRecords = Record<
  string,
  Pick<NotificationProps, 'isActionable' | 'onAction' | 'actionContent'> & {
    message: (msg: string) => string
  }
>

export const Warning = ({
  messageKey,
  message,
  index,
  WARNINGS
}: {
  messageKey: string
  message: string
  index: number
  WARNINGS: WarningRecords
}) => {
  const notifProps = WARNINGS[messageKey]

  return (
    <div key={index}>
      <Notification severity='warning' {...notifProps}>
        {WARNINGS[messageKey]?.message(message) ?? message}
      </Notification>
    </div>
  )
}
