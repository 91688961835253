import { CompletedOrder } from '../types/orders'
import { domain, get } from './util'

export const getCompletedOrder = (
  orderGuid: string
): Promise<CompletedOrder> => {
  return get(
    `https://${domain}/catering/v1/public/orders/completed?orderGuid=${orderGuid}`
  ).then((response) => response.json())
}
