import React, { useMemo } from 'react'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useDiningOptions } from '@local/do-secundo-use-dining-options'

import { DiningOptionBehavior } from '../../../../client/types/cart'

interface AvailabilityContextType {
  orderingAvailable: boolean
  standardOrderingAvailable: boolean
  availability?: Record<DiningOptionBehavior, boolean>
  loading: boolean
  error?: any
}

// @ts-ignore
const AvailabilityContext = React.createContext<AvailabilityContextType>({})

interface AvailabilityProviderProps {
  fastLinkName: string | undefined
  children: React.ReactNode
}

export const AvailabilityProvider: React.FC<AvailabilityProviderProps> = ({
  children,
  fastLinkName
}) => {
  const {
    ooConfig,
    loading: restaurantLoading,
    error: restaurantError
  } = useRestaurant()

  const {
    isLoading: diningOptionsLoading,
    data: diningOptionsData,
    error: diningOptionsError
  } = useDiningOptions(fastLinkName)

  const { data: standardDiningOptionsData } = useDiningOptions(undefined, true)

  const context = useMemo(() => {
    const loading = restaurantLoading || diningOptionsLoading
    const error = restaurantError || diningOptionsError

    if (loading || error || !diningOptionsData) {
      return {
        standardOrderingAvailable: false,
        orderingAvailable: false,
        loading,
        error
      }
    }

    const filteredOptions = diningOptionsData.filter(
      ({ behavior }) => behavior === 'TAKE_OUT' || behavior === 'DELIVERY'
    )

    const standardFilteredOptions = standardDiningOptionsData?.filter(
      ({ behavior }) => behavior === 'TAKE_OUT' || behavior === 'DELIVERY'
    )

    const futureMap = filteredOptions.reduce(
      (acc, { futureSchedule, behavior }) => {
        const { dates } = futureSchedule
        const available = dates.find(({ times = [] }) => times.length >= 1)
        return {
          ...acc,
          [behavior]: Boolean(available)
        }
      },
      {} as Record<DiningOptionBehavior, boolean>
    )

    const standardFutureMap = standardFilteredOptions?.reduce(
      (acc, { futureSchedule, behavior }) => {
        const { dates } = futureSchedule
        const available = dates.find(({ times = [] }) => times.length >= 1)
        return {
          ...acc,
          [behavior]: Boolean(available)
        }
      },
      {} as Record<DiningOptionBehavior, boolean>
    )

    const futureDatesAvailable = Boolean(
      futureMap['TAKE_OUT'] || futureMap['DELIVERY']
    )

    const standardFutureDatesAvailable = standardFutureMap
      ? Boolean(standardFutureMap['TAKE_OUT'] || standardFutureMap['DELIVERY'])
      : false

    const orderingAvailable = futureDatesAvailable && Boolean(ooConfig?.enabled)
    const standardOrderingAvailable =
      standardFutureDatesAvailable && Boolean(ooConfig?.enabled)

    return {
      orderingAvailable,
      standardOrderingAvailable,
      availability: futureMap,
      loading,
      error
    }
  }, [
    standardDiningOptionsData,
    restaurantLoading,
    restaurantError,
    diningOptionsLoading,
    diningOptionsData,
    diningOptionsError,
    ooConfig?.enabled
  ])

  return (
    <AvailabilityContext.Provider value={context}>
      {children}
    </AvailabilityContext.Provider>
  )
}

export const useAvailability = () => React.useContext(AvailabilityContext)
