import React from 'react'

import { Image } from '../components/Image/Image'

export const CreditCardImage = ({ cardType }: { cardType?: string }) => {
  switch (cardType) {
    case 'VISA':
      return (
        <Image
          className='cardLogo'
          alt='Visa icon'
          path='icons/visa-logo.svg'
        />
      )
    case 'MASTERCARD':
      return (
        <Image
          className='cardLogo'
          alt='Mastercard icon'
          path='icons/mastercard-logo.svg'
        />
      )
    case 'AMEX':
      return (
        <Image
          className='cardLogo'
          alt='American Express icon'
          path='icons/amex-logo.svg'
        />
      )
    case 'DISCOVER':
      return (
        <Image
          className='cardLogo'
          alt='Discover icon'
          path='icons/discover-logo.svg'
        />
      )
    case 'JCB':
      return (
        <Image className='cardLogo' alt='JCB icon' path='icons/jcb-logo.svg' />
      )
    case 'DINERS':
      return (
        <Image
          className='cardLogo'
          alt='Diners icon'
          path='icons/diners-club-logo.svg'
        />
      )
    case 'MAESTRO':
      return (
        <Image
          className='cardLogo'
          alt='Maestro icon'
          path='icons/maestro-logo.svg'
        />
      )
    default:
      return (
        <Image
          className='cardLogo'
          alt='Credit card icon'
          path='icons/credit-card-gray.svg'
        />
      )
  }
}
