import React, { useEffect, useState } from 'react'
import { join } from 'path'
import { useQuery } from 'react-query'
import {
  getApplicableConfigs,
  getRestaurantConfig
} from '../../../../client/api/restaurant'
import {
  ApplicableConfigsRequest,
  BrandingConfig,
  ConfigItemType,
  CustomLocationsConfig,
  RestaurantInfo,
  SpecialInstructionsConfig,
  SpotlightConfig,
  OrderTypeConfig,
  PayLaterOption,
  PayLaterConfig,
  TdsConfig
} from '../../../../client/types/config'
import { EventType } from '../../../../client/types/form'

interface RestaurantProviderProps {
  restaurantGuid: string
}

interface ApplicableConfigsMap {
  brandingConfig?: BrandingConfig
  customLocations?: CustomLocationsConfig
  fulfillmentInstructions?: string
  spotlightConfig?: SpotlightConfig
  eventType?: EventType
  payLaterOption?: PayLaterOption
  tdsConfig?: TdsConfig
}

interface RestaurantContextType {
  getRestaurantPath: (path?: string) => string
  restaurantGuid: string
  setBasePath: (basePath: string) => void
  applicableConfigs: ApplicableConfigsMap
  updateApplicableConfigsRequest: (value: ApplicableConfigsRequest) => void
  applicableConfigsLoading?: boolean
  applicableConfigsError?: boolean
}

const RestaurantContext = React.createContext<RestaurantContextType>(
  {} as RestaurantContextType
)

export const RestaurantProvider: React.FC<RestaurantProviderProps> = ({
  restaurantGuid,
  children
}) => {
  const [basePath, setBasePath] = useState<string>('/')

  // Applicable configs returned from config engine
  const [applicableConfigs, setApplicableConfigs] =
    useState<ApplicableConfigsMap>({})

  // Data that might inform the applicable configs
  const [applicableConfigsRequest, setApplicableConfigsRequest] =
    useState<ApplicableConfigsRequest>({})

  // Wrapper to upsert new items into the configs request
  // Updating this state is used to request a new set of applicable configs from the API
  const updateApplicableConfigsRequest = (
    newState: ApplicableConfigsRequest
  ) => {
    setApplicableConfigsRequest({ ...applicableConfigsRequest, ...newState })
  }

  // When applicableConfigsRequest in state is updated, fetch the new applicable configs
  const {
    data: applicableConfigsResp,
    isLoading: applicableConfigsLoading,
    isError: applicableConfigsError
  } = useQuery(['applicable-configs', applicableConfigsRequest], () =>
    getApplicableConfigs(applicableConfigsRequest)
  )

  // When the new applicable configs are done fetching, update the applicableConfigs state
  useEffect(() => {
    if (applicableConfigsResp) {
      let updatedConfigs: ApplicableConfigsMap = {}
      applicableConfigsResp.configItems.forEach((config) => {
        switch (config.type) {
          case ConfigItemType.BRANDING:
            updatedConfigs.brandingConfig = config.value as BrandingConfig
            break
          case ConfigItemType.CUSTOM_LOCATIONS:
            updatedConfigs.customLocations =
              config.value as CustomLocationsConfig
            break
          case ConfigItemType.SPECIAL_FULFILLMENT_INSTRUCTIONS:
            updatedConfigs.fulfillmentInstructions = (
              config.value as SpecialInstructionsConfig
            )?.instructions
            break
          case ConfigItemType.SPOTLIGHT:
            updatedConfigs.spotlightConfig = config.value as SpotlightConfig
            break
          case ConfigItemType.ORDER_TYPE:
            updatedConfigs.eventType = (
              config.value as OrderTypeConfig
            ).eventType
            break
          case ConfigItemType.PAY_LATER:
            updatedConfigs.payLaterOption = (
              config.value as PayLaterConfig
            ).payLaterOption
            break
          case ConfigItemType.TDS:
            updatedConfigs.tdsConfig = config.value as TdsConfig
            break
          default:
            break
        }
      })
      setApplicableConfigs(updatedConfigs)
    }
  }, [applicableConfigsResp])

  // Helper function to build URLs
  const getRestaurantPath = (subpath = '') => {
    return join(basePath, subpath)
  }

  const context = {
    getRestaurantPath,
    restaurantGuid,
    setBasePath,
    applicableConfigs,
    updateApplicableConfigsRequest,
    applicableConfigsLoading,
    applicableConfigsError
  }

  return (
    <RestaurantContext.Provider value={context}>
      {children}
    </RestaurantContext.Provider>
  )
}

/*
 * If there's a name override in the branding config, use that.
 * Otherwise, use the combined "[Rx name] [Location name]" string.
 */
const formatRestaurantName = (
  brandingConfig: BrandingConfig | undefined,
  restaurantInfo: RestaurantInfo
) => {
  const rxNameWithoutLocation = restaurantInfo.name
    .replace(restaurantInfo.locationName ?? '', '')
    .trim()

  if (
    !brandingConfig?.restaurantName ||
    brandingConfig.restaurantName === rxNameWithoutLocation
  ) {
    return restaurantInfo.name
  }

  return brandingConfig.restaurantName
}

export const useRestaurant = () => {
  const context = React.useContext(RestaurantContext)

  const {
    data: restaurantConfig,
    isLoading: loading,
    isError: error
  } = useQuery('oo-config', () => getRestaurantConfig(), {
    staleTime: 5 * 60 * 1000
  })

  const { brandingConfig } = context.applicableConfigs
  const rxName =
    restaurantConfig &&
    formatRestaurantName(brandingConfig, restaurantConfig.restaurantInfo)
  const rxPhone =
    brandingConfig?.phone ?? restaurantConfig?.restaurantInfo.address.phone
  const rxAddress =
    brandingConfig?.address ?? restaurantConfig?.restaurantInfo.address

  return {
    ...context,
    ooConfig: restaurantConfig?.onlineOrderingConfig,
    restaurantInfo: restaurantConfig
      ? {
          ...restaurantConfig.restaurantInfo,
          name: rxName!!,
          address: {
            ...rxAddress!!,
            latitude:
              rxAddress?.latitude ??
              restaurantConfig.restaurantInfo.address.latitude,
            longitude:
              rxAddress?.longitude ??
              restaurantConfig.restaurantInfo.address.longitude,
            phone: rxPhone
          }
        }
      : undefined,
    loading,
    error
  }
}
