import React from 'react'
import { Notification, NotificationProps } from '@local/do-secundo-notification'
import { DeliveryInfo } from '../../types/orders'
import { DiningOptionBehavior } from '../../types/cart'
import { useDeliveryAddressValidator } from '../FulfillmentSelectorModal/fulfillment-helpers'

interface Props {
  deliveryInfo?: DeliveryInfo
  diningOptionBehavior: DiningOptionBehavior
  customLocationName?: string
}

export const NotInDeliveryAreaWarning: React.FC<
  Omit<NotificationProps, 'children'> & Props
> = (props: Props) => {
  const { valid, message } = useDeliveryAddressValidator(
    props.deliveryInfo,
    props.diningOptionBehavior,
    props.customLocationName
  )
  return !valid ? (
    <Notification severity='warning' {...props}>
      {message || 'Unknown error'}
    </Notification>
  ) : null
}
