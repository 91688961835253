import * as React from 'react'
// @ts-ignore
import { v4 as uuid } from 'uuid'
import { NamespacedStorage } from '../../../../client/utils/namespaced-storage'
import {
  PUSH_MODIFICATION_ERRORS,
  CLEAR_MODIFICATION_ERRORS,
  CartAction,
  ModifiedCartItem
} from './cart-actions'
import {
  getCart,
  getCartMeta,
  updateCustomer
} from '../../../../client/api/cart'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Cart, CartMetadataResponse } from '../../../../client/types/cart'
import { useQueryClient } from 'react-query'
import { getCartQueryKey } from '../../../../client/utils/cart-helpers'
import { useCustomerAuth } from '../../../../client/auth/CustomerAuthContext'

interface CartState {
  modificationErrors: {
    id?: string
    items: ModifiedCartItem[]
    message?: string
  }[]
}

const initialCartState: CartState = {
  modificationErrors: []
}

const cartStateReducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    case PUSH_MODIFICATION_ERRORS:
      const newErrorsWithUuid =
        action.modificationErrors?.map((obj) => ({
          ...obj,
          id: uuid() as string
        })) || []
      return {
        ...state,
        modificationErrors: [...state.modificationErrors, ...newErrorsWithUuid]
      }
    case CLEAR_MODIFICATION_ERRORS:
      return {
        ...state,
        modificationErrors: []
      }
    default:
      throw new Error(
        `action type ${action.type} is not defined in the cartState: cartModificationsReducer`
      )
  }
}

interface CartContextType {
  restaurantGuid: string
  cartGuid?: string
  updateCartCache: (cart: Cart) => void
  deleteCartGuid: (fastLinkName: string | undefined) => void
  cartState: CartState
  dispatch: React.Dispatch<CartAction>
  willEarnLoyalty: boolean
  setWillEarnLoyalty: React.Dispatch<boolean>
  optedInTo3pAccountLookup: boolean
  setOptedInTo3pAccountLookup: React.Dispatch<boolean>
  initialCartNotFound: boolean
  setInitialCartNotFound: React.Dispatch<boolean>
  initialCartMetadata: CartMetadataResponse | undefined
}

//@ts-ignore
const CartContext = React.createContext<CartContextType>({})

interface CartProviderProps {
  restaurantStorage: NamespacedStorage
  restaurantGuid: string
  fastLinkName: string | undefined
  children: React.ReactNode
}

export const CartProvider: React.FC<CartProviderProps> = ({
  restaurantStorage,
  restaurantGuid,
  fastLinkName,
  children
}) => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const navigate = useNavigate()
  const [initialCartNotFound, setInitialCartNotFound] = useState(false)
  const [initialCartMetadata, setInitialCartMetadata] =
    useState<CartMetadataResponse>()

  const queryClient = useQueryClient()

  const getOtherCachedCartGuid = (fastLinkName: string | undefined) => {
    return restaurantStorage.get(`cart:${fastLinkName || 'default'}`)
  }

  const getCartGuid = () => {
    const cached = restaurantStorage.get('cartGuid')
    if (cached && cached['value']) {
      return cached.value
    } else {
      return cached
    }
  }
  const [cartGuid, setCartGuid] = useState(getCartGuid())
  const refreshCartGuid = () => {
    setCartGuid(getCartGuid())
  }

  const [optedInTo3pAccountLookup, setOptedInTo3pAccountLookup] =
    useState(false)
  const [willEarnLoyalty, setWillEarnLoyalty] = useState(false)

  const updateCartGuid = (
    newCartGuid: string,
    fastLinkName: string | undefined
  ) => {
    if (newCartGuid !== cartGuid) {
      restaurantStorage.set('cartGuid', {
        value: newCartGuid
      })

      // where the cart was last used
      restaurantStorage.set(`cart:${fastLinkName || 'default'}`, newCartGuid)

      refreshCartGuid()
    }
  }

  const updateCartCache = (response: Cart) => {
    queryClient.setQueryData(getCartQueryKey(response.guid), response)

    updateCartGuid(response.guid, response.metadata?.fastLinkName)
  }

  const deleteCartGuid = (fastLinkName: string | undefined) => {
    if (cartGuid) {
      restaurantStorage.remove('cartGuid')
      restaurantStorage.remove(`cart:${fastLinkName || 'default'}`)

      refreshCartGuid()
      setOptedInTo3pAccountLookup(false)
    }
  }

  useEffect(() => {
    // shared cart case
    const initialCartGuid = queryParams.get('cart')
    if (initialCartGuid) {
      getCart(initialCartGuid, fastLinkName)
        .then((cart) => {
          updateCartCache(cart)
          setInitialCartMetadata({ metadata: cart.metadata })
        })
        .catch(() => {
          deleteCartGuid(fastLinkName)
          setInitialCartNotFound(true)

          getCartMeta(initialCartGuid)
            .then((meta) => setInitialCartMetadata(meta))
            .catch(() => setInitialCartMetadata(undefined))
        })
        .finally(() => {
          queryParams.delete('cart')
          navigate(
            {
              search: queryParams.toString()
            },
            { replace: true }
          )
        })
    } else {
      const cartGuidToLoad = cartGuid || getOtherCachedCartGuid(fastLinkName)

      if (cartGuidToLoad) {
        getCart(cartGuidToLoad, fastLinkName)
          .then((cart) => {
            updateCartCache(cart)
          })
          .catch(() => {
            // switched to or from independent menu case
            // try for the default cart if there isn't one for this special menu
            const otherCartGuid =
              getOtherCachedCartGuid(fastLinkName) ||
              getOtherCachedCartGuid(undefined)
            if (otherCartGuid && otherCartGuid != cartGuidToLoad) {
              getCart(otherCartGuid, fastLinkName)
                .then((cart) => {
                  updateCartCache(cart)
                })
                .catch(() => {
                  deleteCartGuid(fastLinkName)
                })
            } else {
              deleteCartGuid(fastLinkName)
            }
          })
        setInitialCartMetadata(undefined)
      } else {
        // no cart in memory case
        setInitialCartMetadata(undefined)
        refreshCartGuid()
      }
    }
  }, [fastLinkName])

  const { customer } = useCustomerAuth()

  useEffect(() => {
    if (cartGuid && customer) {
      updateCustomer(cartGuid, {
        email: customer?.email ?? '',
        firstName: customer?.firstName ?? '',
        lastName: customer?.lastName ?? '',
        phone: customer?.phone ?? ''
      })
    }
  }, [cartGuid, customer])

  const [cartState, dispatch] = React.useReducer(
    cartStateReducer,
    initialCartState
  )

  useEffect(() => {
    if (!initialCartNotFound) {
      setInitialCartMetadata(undefined)
    }
  }, [initialCartNotFound])

  const context = {
    restaurantGuid,
    cartGuid,
    updateCartCache,
    deleteCartGuid,
    cartState,
    dispatch,
    optedInTo3pAccountLookup,
    setOptedInTo3pAccountLookup,
    willEarnLoyalty,
    setWillEarnLoyalty,
    initialCartNotFound,
    setInitialCartNotFound,
    initialCartMetadata
  }

  return <CartContext.Provider value={context}>{children}</CartContext.Provider>
}

export const CartConsumer: React.FC<{
  children: (context: CartContextType) => React.ReactNode
}> = ({ children }) => (
  <CartContext.Consumer>{(context) => children(context)}</CartContext.Consumer>
)

export const useCart = () => React.useContext<CartContextType>(CartContext)
