import React, { FC, useCallback, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { parsePhoneNumber } from 'libphonenumber-js'
import isEmail from 'validator/lib/isEmail'
import * as yup from 'yup'
import { AccountCreationFormInputs } from './PwlessAuthModal'
import { useCustomerAuth } from './CustomerAuthContext'
import { profileInformationSchema } from './auth-form-helpers'
import { alertSuccess } from './alertUtils'
import InputField from './InputField'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CAInfo, ToastTOSAndPrivacy } from './ToastTOSAndPrivacy'

import styles from './auth.module.css'

type CompleteProfileProps = {
  accountInfo: AccountCreationFormInputs
  checkingOut?: boolean
  onSuccessfulLogin: () => void
  onClose: () => void
}

type FormInputs = {
  email: string
  firstName: string
  lastName: string
}

export const CompleteProfileForm: FC<CompleteProfileProps> = ({
  accountInfo,
  checkingOut = false,
  onSuccessfulLogin,
  onClose
}) => {
  const { completeSignup, fetchCustomer } = useCustomerAuth()
  const [submitting, setSubmitting] = useState(false)
  // We don't need to validate the phone field, remove it from the resolver
  const { phone, ...schemaFields } = profileInformationSchema.fields
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
    resolver: yupResolver(yup.object(schemaFields)),
    defaultValues: {
      email: accountInfo.email,
      firstName: accountInfo.firstName,
      lastName: accountInfo.lastName
    }
  })
  const email = useWatch({
    name: 'email',
    control: formMethods.control,
    defaultValue: accountInfo.email
  })
  const firstName = useWatch({
    name: 'firstName',
    control: formMethods.control,
    defaultValue: accountInfo.firstName
  })
  const lastName = useWatch({
    name: 'lastName',
    control: formMethods.control,
    defaultValue: accountInfo.lastName
  })
  const { handleSubmit, setValue } = formMethods
  const [hasError, setHasError] = useState(false)
  const { customer } = useCustomerAuth()
  const onSuccessfulAccountCreation = useCallback(() => {
    onSuccessfulLogin()
    onClose()
    alertSuccess(
      customer?.firstName ? `Welcome, ${customer?.firstName}!` : 'Welcome!'
    )
  }, [customer?.firstName, onClose, onSuccessfulLogin])

  const submit = useCallback(
    async (inputData: FormInputs) => {
      setSubmitting(true)
      try {
        const success = await completeSignup(
          inputData.email,
          inputData.firstName,
          inputData.lastName
        )
        if (success) {
          await fetchCustomer()
          onSuccessfulAccountCreation()
        } else {
          setHasError(true)
        }
      } catch (err) {
        setHasError(true)
      } finally {
        setSubmitting(false)
      }
    },
    [completeSignup, fetchCustomer, onSuccessfulAccountCreation]
  )

  const onBlurInput = (emailValue: string) => () => {
    setValue('email', emailValue, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }
  return (
    <FormProvider {...formMethods}>
      <div className={styles.headerContent}>
        <h2 className={styles.title} id='pwless-auth-modal-header'>
          Complete your Toast account
        </h2>
      </div>
      {/* Stop propagation of the event so that submitting this form doesn't also trigger a submit of any parent forms, like the checkout form */}
      <form
        className={styles.completeProfileForm}
        onSubmit={(event) => {
          event.stopPropagation()
          handleSubmit(submit)(event)
        }}
      >
        <div className={styles.formBody}>
          {hasError ? (
            <span className={styles.formError}>
              The information you entered was invalid. Please try again.
            </span>
          ) : null}
          <InputField
            id='phone'
            type='text'
            label='Phone Number'
            disabled
            value={parsePhoneNumber(
              accountInfo.phoneNumber,
              'US'
            ).formatNational()}
          />
          <div className='inputs'>
            <InputField
              id='firstName'
              type='text'
              label='First name'
              required
            />
            <InputField id='lastName' type='text' label='Last name' required />
            <InputField
              id='email'
              type='email'
              label='Email'
              required
              validate={(value) =>
                isEmail(value) || 'enter a valid email address'
              }
              onBlur={onBlurInput(email)}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            className={styles.submitBtn}
            type='submit'
            disabled={
              submitting || !(isEmail(email || '') && firstName && lastName)
            }
            testId='create-account-button'
          >
            {checkingOut ? 'Create account and check out' : 'Create account'}
          </Button>
          <div className={styles.legal} role='contentinfo'>
            By providing your information, you give Toast and this
            restaurant/restaurant group permission to send you informational
            text messages and emails (such as order status updates and digital
            receipts). Your personal information, and order & reservation
            history will be tied to this account. Payment information may be
            stored and used for this and future orders from Toast restaurants.
            Message & data rates may apply, msg frequency varies. Reply STOP to
            opt out. Subject to <ToastTOSAndPrivacy /> and Merchant&apos;s Terms
            and Policies apply. <CAInfo />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
