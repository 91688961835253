import * as amplitude from '@amplitude/analytics-browser'

import { amplitudeApiKey } from '../config'
import { Component } from 'react'

export class Tracker extends Component {
  constructor(props: any) {
    super(props)

    // Public methods
    this.trackRestaurantPageUtmSource =
      this.trackRestaurantPageUtmSource.bind(this)
    this.trackAddItem = this.trackAddItem.bind(this)
    this.trackPlaceOrder = this.trackPlaceOrder.bind(this)
    this.trackPlaceOrderError = this.trackPlaceOrderError.bind(this)
    this.trackAccountLogin = this.trackAccountLogin.bind(this)
    this.trackAccountLogout = this.trackAccountLogout.bind(this)
    this.trackSpiScriptLoadSuccess = this.trackSpiScriptLoadSuccess.bind(this)
    this.trackSpiScriptLoadFailure = this.trackSpiScriptLoadFailure.bind(this)
    this.trackSpiInitializeSuccess = this.trackSpiInitializeSuccess.bind(this)
    this.trackSpiInitializeFailure = this.trackSpiInitializeFailure.bind(this)
    this.trackSpiCreatePaymentSuccess =
      this.trackSpiCreatePaymentSuccess.bind(this)
    this.trackSpiCreatePaymentFailure =
      this.trackSpiCreatePaymentFailure.bind(this)
    this.trackSpiConfirmPaymentSuccess =
      this.trackSpiConfirmPaymentSuccess.bind(this)
    this.trackSpiConfirmPaymentFailure =
      this.trackSpiConfirmPaymentFailure.bind(this)
    this.trackUpdatePaymentIntentPaymentSuccess =
      this.trackUpdatePaymentIntentPaymentSuccess.bind(this)
    this.trackUpdatePaymentIntentPaymentFailure =
      this.trackUpdatePaymentIntentPaymentFailure.bind(this)

    if (typeof window !== 'undefined') {
      amplitude.init(amplitudeApiKey, undefined, {
        disableCookies: true
      })
    }
  }

  trackRestaurantPageUtmSource(utmSource: string, restaurant_guid: string) {
    this.track('Visit COO Site', {
      utm_source: utmSource,
      restaurant_guid: restaurant_guid
    })
  }

  trackAddItem(restaurant_guid: string, logged_in: boolean) {
    this.track('Add COO Item', {
      restaurant_guid: restaurant_guid,
      logged_in: logged_in
    })
  }

  trackPlaceOrder(restaurant_guid: string, logged_in: boolean) {
    this.track('Place COO Order', {
      restaurant_guid: restaurant_guid,
      logged_in: logged_in
    })
  }

  trackPlaceOrderError(restaurant_guid: string, logged_in: boolean) {
    this.track('Error Placing COO Order', {
      restaurant_guid: restaurant_guid,
      logged_in: logged_in
    })
  }

  trackAccountLogin(restaurant_guid: string) {
    this.track('Guest Logged In', {
      restaurant_guid: restaurant_guid
    })
  }

  trackAccountLogout(restaurant_guid: string) {
    this.track('Guest Logged Out', {
      restaurant_guid: restaurant_guid
    })
  }

  private track(name: string, data: any) {
    if (typeof window !== 'undefined') {
      amplitude.track(name, data)
    }
  }

  /** SPI tracking - these can be removed once SPI release is stable */

  trackSpiScriptLoadSuccess(restaurant_guid: string) {
    this.track('SPI - load SDK success', {
      restaurant_guid
    })
  }

  trackSpiScriptLoadFailure(restaurant_guid: string) {
    this.track('SPI - load SDK failed', {
      restaurant_guid
    })
  }

  trackSpiInitializeSuccess(restaurant_guid: string) {
    this.track('SPI - initialize success', {
      restaurant_guid
    })
  }

  trackSpiInitializeFailure(restaurant_guid: string) {
    this.track('SPI - initialize failed', {
      restaurant_guid
    })
  }

  trackSpiCreatePaymentSuccess(restaurant_guid: string) {
    this.track('SPI - create payment success', {
      restaurant_guid
    })
  }

  trackSpiCreatePaymentFailure(restaurant_guid: string) {
    this.track('SPI - create payment failed', {
      restaurant_guid
    })
  }

  trackSpiConfirmPaymentSuccess(restaurant_guid: string) {
    this.track('SPI - confirm payment success', {
      restaurant_guid
    })
  }

  trackSpiConfirmPaymentFailure(restaurant_guid: string) {
    this.track('SPI - confirm payment failed', {
      restaurant_guid
    })
  }

  trackUpdatePaymentIntentPaymentSuccess(restaurant_guid: string) {
    this.track('SPI - update payment intent success', {
      restaurant_guid
    })
  }

  trackUpdatePaymentIntentPaymentFailure(restaurant_guid: string) {
    this.track('SPI - update payment intent failed', {
      restaurant_guid
    })
  }
}

// Singleton instance used for tracking events on the client
const tracker = new Tracker({})

export const useTracker = () => {
  return tracker
}
