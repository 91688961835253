import React, { memo } from 'react'

import GreyHorizontal from '../../assets/powered-by/grey-horizontal.svg'
import { Link } from '../Link/Link'
import { DISCOVER_LINK } from '../../api/util'

// Coming soon:
// import OrangeHorizontal from '../../assets/powered-by/orange-horizontal.svg'
// import WhiteHorizontal from '../../assets/powered-by/white-horizontal.svg'
// import GreyStacked from '../../assets/powered-by/grey-stacked.svg'
// import WhiteStacked from '../../assets/powered-by/white-stacked.svg'
// import OrangeStacked from '../../assets/powered-by/orange-stacked.svg'

export const PoweredByToast = memo(({ ...props }: { className?: string }) => {
  return (
    <Link to={DISCOVER_LINK} target='_blank'>
      <GreyHorizontal {...props} />
    </Link>
  )
})
